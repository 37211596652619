import React, { Component } from "react";

class NoDataAnswer extends Component {
  render() {
    return (
      <tr><td colspan="3">No records to display.</td></tr>
    );
  }
}

export default NoDataAnswer;
