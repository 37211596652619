import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

class AlertMessageBootstrap extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    

    handleClose = () => {    
        this.props.unmountMe();
    }
    render() {
        return (             
                <Alert variant={this.props.messageType} onClose={this.handleClose} dismissible>
                  {this.props.message}
                </Alert>
        );
    }

    componentDidMount(){
        setTimeout(() => {
            this.handleClose();
        }, 5000);
    }


}

export default AlertMessageBootstrap;