import React, { Component } from 'react';
import { Link } from "react-router-dom";
//import AlertMessageBootstrap from '../general/AlertMessageBootstrap';
import MaintStatus from './components/MaintStatus';
import queryString from 'query-string'
import ModalDialogDelete from '../general/ModalDialogDelete';

require('dotenv').config()
const axios = require("axios");

class Maintenance extends Component {
    constructor(props) {
      super(props);
      this.state = {
        bgStatus: "",
        rows: [],
        ModalDialog: "",
        stringQuery: "",
        type:"",
        status: ""
      };
    }

  filterResults = () => {
    return window.location.replace(`?stringQuery=${this.state.stringQuery}&type=${this.state.type}&status=${this.state.status}`);
  }

  handleData = () => {
      var vstart = 0;
      var vlimit = 0;
      var self = this;
      const parsed = queryString.parse(this.props.location.search);
      axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}maint/?start=${vstart}&limit=${vlimit}&q=${parsed.stringQuery}&status=${parsed.status}&type=${parsed.type}`
        })
          .then(function(response) {

            if (response.data.result.data.results.lenth === 0) {
              return "There is no maintenances to list!"
            }

            var stringQuery = parsed.stringQuery || ""
            var type = parsed.type || ""
            var status = parsed.status || ""

            self.setState({rows: response.data.result.data.results,
              stringQuery: stringQuery,
              type: type,
              status: status
            });
            

          })
          .catch(function(response) {
            console.log(response);
            return "<span>failure</span>";
          });
  }

  handleChildUnmount = () => {
    this.setState({ModalDialog: ""})
  }


  handleDeleteModal = (idRow, subject) => {
    this.setState({ModalDialog: ""})
    this.setState({ModalDialog: <ModalDialogDelete name={subject} idRow={idRow} unmountMe={this.handleChildUnmount} deleteMe={this.handleDelete} />})
  }

  handleDelete = id => {
    this.setState({ModalDialog: ""})
    var self = this;
      axios({
          method: "delete",
          url: `${process.env.REACT_APP_API_URL}maint/${id}`
        })
          .then(function(response) {
            
            const rows = self.state.rows.filter(row => row.id !== id);
            self.setState({ rows })      

          })
          .catch(function(response) {
            console.log(response);
          });
    
  }


    loadBgStatus() {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/maint/status`
      })
        .then(function(response) {
          var bgStatus = [];
          for (let i = 0; i < response.data.result.data.results.length; i++) {
           console.log(response.data.result.data.results[i])
           bgStatus.push(<MaintStatus data={response.data.result.data.results[i]} key={response.data.result.data.results[i].id} />)
          }
          self.setState({bgStatus: bgStatus})
        })
        .catch(function(response) {
          console.log(response);
        });
    }

    handleChange = e => {
      const value = e.target.value;
      const id = e.target.id;
      this.setState({ [id]: [value] });
      console.log(value, id)
    };

    componentDidMount() {
      this.loadBgStatus();
      this.handleData();    
    }




    render() {
        return (
            <div className="br-mainpanel">
            <div className="br-pageheader pd-y-15 pd-l-20">
              <nav className="breadcrumb pd-0 mg-0 tx-12">
                <a className="breadcrumb-item" href="index.html">
                  Superior Jetties
                </a>
                <span className="breadcrumb-item active">Maintenance</span>
              </nav>
            </div>
    
            <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
                    <div>
                       <h4 className="tx-gray-800 mg-b-5">Maintenance</h4>
                    </div>
                    <Link to={`./form/new`} className="btn btn-primary btn-oblong tx-11 tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2">ADD NEW</Link>
            </div>
  
           <div className="br-pagebody">

             <div className="row row-sm mb-4">
               {/* this.state.bgStatus */}
              </div>
           
              <div className="br-section-wrapper">
            <div className="table-wrapper">
            
            <div class="d-flex bg-gray-100 align-items-center ht-md-80 bd pd-x-20 mb-5">
            <div class="col-lg-4">
              <input type="text" id="stringQuery" class="form-control" placeholder="Asset, Subject..." onChange={this.handleChange} value={this.state.stringQuery}/>
            </div>
            <div class="col-lg-2">
              <select class="form-control ml-1" id="type" onChange={this.handleChange} value={this.state.type}>
                <option value="">-- All Types --</option>
                <option value="1">Preventive</option>
                <option value="2">Scheduled</option>
                <option value="3">Corrective</option>
              </select>
            </div>
            <div class="col-lg-2">
              <select class="form-control ml-1" id="status" onChange={this.handleChange} value={this.state.status}>
                <option value="">-- All Status --</option>
                <option value="1">Not touched</option>
                <option value="2">In progress</option>
                <option value="3">Completed</option>
                <option value="4">Archived</option>
              </select>
            </div>
            <div class="col-lg-2">
            <input type="submit" class="btn btn-primary" value="Filter" onClick={() => this.filterResults()} />
              </div></div>
             


              <table
                id="datatable1"
                className="table display responsive nowrap"
              >
                <thead>
                  <tr>
                    <th className="wd-5p">#</th>
                    <th className="wd-30p">Asset</th>
                    <th className="wd-30p">Subject</th>
                    <th className="wd-10p">Type</th>
                    <th className="wd-10p">Status</th>
                    <th className="wd-30p">{" "}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.map(rows => <tr>
                        <th>{rows.id}</th>
                        <th><a href={`./form/${rows.id}`}>{rows.assetName}</a></th>
                        <th>{rows.subject}</th>
                        <th>{rows.type}</th>
                        <th>{rows.status}</th>
                        <th className="text-right"><a href={`./form/${rows.id}`}><i class="icon ion-edit tx-20"></i></a>
                        <i class="icon ion-close tx-20 mg-l-20" onClick={() => this.handleDeleteModal(rows.id, rows.subject)}></i>
                        </th>
                       </tr>)}
                </tbody>
                {this.state.ModalDialog}
              </table>
              
            </div>
          </div>

           </div>
           
            <footer className="br-footer">
              <div className="footer-left">
                <div className="mg-b-2">
                  Copyright &copy; 2019. Superior Jetties Group. All Rights
                  Reserved.
                </div>
                <div>Developed by Media Booth Australia</div>
              </div>
            </footer>
          </div>
         
        );
    }
}

export default Maintenance;