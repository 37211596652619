import React, { Component } from 'react';
import InboxMainPanel from "./InboxMainPanel";
import MaintenanceListRequest from "./MaintenanceListRequest";

require('dotenv').config()
const axios = require("axios");

class MaintenanceInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inboxMainPanel: false,
            maintenanceListRequest: false
        }
        this.reloadListRequest = this.reloadListRequest.bind(this);
    }


    reloadListRequest() {
        this.setState({maintenanceListRequest: false})
        setTimeout(() => {
            this.setState({maintenanceListRequest: <MaintenanceListRequest callDetails={this.callDetails} />})
        }, 5);
    }
    componentDidMount() {
       // this.loadData();
    this.setState({maintenanceListRequest: <MaintenanceListRequest callDetails={this.callDetails} />})
    }

    loadData = (id) => {
        const varid = id || 34 
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}maintenanceRequest/inbox/show/${varid}`
          })
            .then(response => {
                this.setState({inboxMainPanel : <InboxMainPanel data={response.data.data.results[0]} reloadListRequest={this.reloadListRequest} />});
            })      
    }
    
    callDetails = id => {
        this.setState({inboxMainPanel : false});
        this.loadData(id)
    }
    render() {
        return (
            <div>
                {this.state.maintenanceListRequest}
                {this.state.inboxMainPanel}
            </div>
        );
    }
}

export default MaintenanceInbox;