import React, { Component } from 'react';

class InboxCard extends Component {
    render() {
        //console.log("props", this.props)


        function showAttachedIcon(a) {
            if (a !== null && a !== 0) {
                return <i className="icon ion-android-attach mr-2"></i>
            }

            return false;

        }


        function showMaps(lat, long) {
            if (lat !== null && long !== null) {
                return <i className="icon ion-pinpoint"></i>
            }

            return false;

        }

        function formatData(d) {
            const date = new Date(d)
            const today = new Date()
            
            // To calculate the time difference of two dates 
            var Difference_In_Time = today.getTime() - date.getTime(); 
            
            // To calculate the no. of days between two dates 
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 

            if (Difference_In_Days < 0.5) {
                const hours = date.getHours()<10 ? "0"+date.getHours() : date.getHours()
                const minutes = date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes()
                return hours + ":" + minutes
            } else if (Difference_In_Days < 2) {
                return "Yesterday"
            } else if (Difference_In_Days < 7) {
                return `${parseInt(Difference_In_Days)} Days Ago`
            } else {
                return date.toDateString()
            }
        }

        return (
            <div className={`br-mailbox-list-item`} onClick={() => this.props.callDetails(this.props.data.id)}>
                <div className="d-flex justify-content-between mg-b-5">
                    <div>
                        {showAttachedIcon(this.props.data.attached)}
                        {showMaps(this.props.data.latitude, this.props.data.latitude)}
                    </div>
                    <span className="tx-12">{formatData(this.props.data.createdIn)}</span>
                </div>
                <h6 className="tx-14 mg-b-10 tx-gray-800">{this.props.data.name} ({this.props.data.department})</h6>
                <p className="tx-12 tx-gray-600 mg-b-5">{this.props.data.details}...</p>
            </div>
        );
    }
}

export default InboxCard;