import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import AlertMessageBootstrap from '../general/AlertMessageBootstrap';
require('dotenv').config()
const axios = require("axios");

class SideTableForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
          name: '',
          status: 1,
          RedirectPage: '',
          AlertMessage: '',
          gobackPath: this.props.match.path.replace(":id","")
        };
        this.handleData();
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }

    handleCloseAlert() {
      this.setState({AlertMessage: false});
    } 

    handleData() {
      if (!isNaN(this.props.match.params.id)) {
        var self = this;
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}sidetable/${
            self.props.details.table
          }/${self.props.match.params.id}`
        })
          .then(function(response) {
            self.setState({name: response.data.data.results[0].name, status: response.data.data.results[0].status})
          })
          .catch(function(response) {
            console.log(response);
          });
      } 
    }


    handleChangeName = (e) => {
      const name = e.target.value;
      this.setState({ name });
    };

    handleChangeSatus = (e) => {
      const status = e.target.value;
      this.setState({ status });
    };

    submitForm() {
      if (!isNaN(this.props.match.params.id)) {
        this.updateData()
      } else {
        this.insertData()  
      }
    }

    insertData() {
      var self = this;
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}sidetable/${
            self.props.details.table
          }`,
          data: {
            name: self.state.name,
            status: self.state.status
          }
        })
          .then(function(response) {
            var RedirectPage = []
            RedirectPage.push(<Redirect to={`${
              self.state.gobackPath + response.data.id
            }`} key="1" />)
            

            self.setState({RedirectPage: RedirectPage})

            var CAlertMessage = []
            if (response.data.success === true) {
              CAlertMessage.push(<AlertMessageBootstrap messageType="success" message={response.data.msg} unmountMe={self.handleCloseAlert} />)
              self.setState({AlertMessage: CAlertMessage})
            } else {
              CAlertMessage.push(<AlertMessageBootstrap messageType="danger" message={response.data.msg} unmountMe={self.handleCloseAlert} />)
              self.setState({AlertMessage: CAlertMessage})
            }
            
          })
          .catch(function(response) {
            var CAesslertMessage = []
            CAesslertMessage.push(<AlertMessageBootstrap messageType="danger" message="Update failure!"  unmountMe={self.handleCloseAlert}/>)
            self.setState({AlertMessage: CAesslertMessage})
          });
    }

    updateData() {
      var self = this;
      axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}sidetable/${
          self.props.details.table
        }/${this.props.match.params.id}`,
        data: {
          name: self.state.name,
          status: self.state.status
        }
      })
        .then(function(response) {
          var CAlertMessage = []
          CAlertMessage.push(self.state.AlertMessage)
          CAlertMessage.push(<AlertMessageBootstrap messageType="success" message={response.data.msg}  unmountMe={self.handleCloseAlert}/>)
          self.setState({AlertMessage: CAlertMessage})
          
        })
        
        .catch((response) => {
          console.log(response);
          var CAesslertMessage = []
          self.setState({AlertMessage: " "})
          CAesslertMessage.push(<AlertMessageBootstrap messageType="danger" message="Update failure!"  unmountMe={self.handleCloseAlert}/>)
          self.setState({AlertMessage: CAesslertMessage})
        });
    }


    // componentDidMount() {
    //   this.handleData() 
    // }
    
    render() {
        return (
            <div className="br-mainpanel">
               {this.state.RedirectPage}
            <div className="br-pageheader pd-y-15 pd-l-20">
              <nav className="breadcrumb pd-0 mg-0 tx-12">
                <a className="breadcrumb-item" href="index.html">
                  Superior Jetties
                </a>
                <a className="breadcrumb-item" href="/">
                  Utilities
                </a>
                <span className="breadcrumb-item active">{this.props.details.title}</span>
              </nav>
            </div>
    
            <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
                    <div>
                       <h4 className="tx-gray-800 mg-b-5">{this.props.details.title}</h4>
                    </div>
                    <Link to={this.state.gobackPath} className="btn btn-outline-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2">Go Back</Link>
            </div>
  
            <div className="br-pagebody">
              <div className="br-section-wrapper">
                <div className="table-wrapper">
                <div className="col-xl-6">
                  <div className="form-group">
                    <label>{this.props.details.title} Name</label>
                    <input type="text" className="form-control" id="name" placeholder={`${this.props.details.title} Description`} value={this.state.name} onChange={this.handleChangeName} />
                  </div>
                  <div className="form-group">
                    <label>Status</label>
                    <select className="form-control" id="status" value={this.state.status}  onChange={this.handleChangeSatus}>
                      <option value="1" >Active</option>
                      <option value="0" >Disabled</option>
                    </select>
                  </div>
                  <input type="submit" className="btn btn-primary mb-4" onClick={this.submitForm.bind(this)} />
                  {this.state.AlertMessage}
                </div>
                
                </div>
              </div>
            </div>
            <footer className="br-footer">
              <div className="footer-left">
                <div className="mg-b-2">
                  Copyright &copy; 2019. Superior Jetties Group. All Rights
                  Reserved.
                </div>
                <div>Developed by Media Booth Australia</div>
              </div>
            </footer>
          </div>
         
        );
    }
}

export default SideTableForm;