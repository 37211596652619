import React, { Component } from 'react';

class DashboardPanel extends Component {
    render() {
        return (
            <div className="br-mainpanel">
                <div className="br-pageheader pd-y-15 pd-l-20">
                    <nav className="breadcrumb pd-0 mg-0 tx-12">
                    <a className="breadcrumb-item" href="index.html">Superior Jetties</a>
                    <a className="breadcrumb-item" href="/">Assets Control</a>
                    <span className="breadcrumb-item active">Dashboard</span>
                    </nav>
                </div>
                <div className="pd-x-30 pd-t-30">
                     <h4 className="tx-gray-800 mg-b-5">Dashboard</h4>
                </div>
           
            </div>
        );
    }
}

export default DashboardPanel;