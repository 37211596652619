import React, { Component } from 'react';

class MaintStatus extends Component {
    render() {
        return (
            <div className="col-sm-6 col-xl-4 mb-3">
                  <div className={`${this.props.data.color} rounded overflow-hidden`}>
                    <div className="pd-25 d-flex align-items-center">
                      <i className={`ion ${this.props.data.icon} tx-60 lh-0 tx-white op-7`}></i>
                      <div className="mg-l-20">
                        <p className="tx-10 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-white-8 mg-b-10">
                            <span className="text-light">{this.props.data.name}</span>
                        </p>
                        <p className="tx-24 tx-white tx-lato tx-bold mg-b-2 lh-1">
                          <span className="text-light">{this.props.data.total}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
        );
    }
}

export default MaintStatus;