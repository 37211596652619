import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import AssetsStatusMenu from "./assets/AssetsStatusMenu";
require('dotenv').config()
const axios = require("axios");

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.loadStatusData();
        this.state = {
            dataStatus: "",
          };
    }
    loadStatusData() {
        var self = this;
        var dataHtml = [];
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}asset/status`
        })
          .then(function(response) {
            var statusList = response.data.result.data.results;
            for (let i = 0; i < statusList.length; i++) {
              dataHtml.push(
                <AssetsStatusMenu
                  title={statusList[i].name}
                  value={statusList[i].total}
                  color={statusList[i].color}
                  icon={statusList[i].icon}
                  key={statusList[i].id}
                />
              );
            }
            self.setState({ dataStatus: dataHtml });
          })
          .catch(function(response) {
            console.log(response);
          });
      }


    render() {
        return (
            <div className="br-sideleft overflow-y-auto">
            <label className="sidebar-label pd-x-15 mg-t-20">Navigation</label>
            <div className="br-sideleft-menu">
                <Link to="/admin/dashboard" className="br-menu-link">
                <div className="br-menu-item">
                    <i className="menu-item-icon icon ion-ios-home-outline tx-22"></i>
                    <span className="menu-item-label">Dashboard</span>
                </div>
                </Link>
                <Link to="/admin/assets" className="br-menu-link">
                <div className="br-menu-item">
                    <i className="menu-item-icon icon ion-ios-gear-outline tx-24"></i>
                    <span className="menu-item-label">Assets</span>
                </div>
                </Link>

                <Link to="/admin/maintenance/inbox" className="br-menu-link">
                <div className="br-menu-item">
                    <i className="menu-item-icon icon ion-clipboard tx-24"></i>
                    <span className="menu-item-label">Maintenance Request</span>
                </div>
                </Link>

                <Link to="/admin/maintenance/" className="br-menu-link">
                <div className="br-menu-item">
                    <i className="menu-item-icon icon ion-settings tx-24"></i>
                    <span className="menu-item-label">Maintenance</span>
                </div>
                </Link>


                <Link to="/" className="br-menu-link">
                <div className="br-menu-item">
                    <i className="menu-item-icon icon ion-ios-briefcase-outline tx-22"></i>
                    <span className="menu-item-label">Utilities</span>
                    <i className="menu-item-arrow fa fa-angle-down"></i>
                </div>
                </Link>
                <ul className="br-menu-sub nav flex-column">
                <li className="nav-item"><Link to="/admin/st/company" className="nav-link">Company</Link></li>
                <li className="nav-item"><Link to="/admin/st/brand" className="nav-link">Brand</Link></li>
                <li className="nav-item"><Link to="/admin/st/location" className="nav-link">Location</Link></li>
                <li className="nav-item"><Link to="/admin/st/category" className="nav-link">Category</Link></li>
                <li className="nav-item"><Link to="/admin/st/condition" className="nav-link">Condition</Link></li>
                <li className="nav-item"><Link to="/admin/st/area" className="nav-link">Area</Link></li>
                <li className="nav-item"><Link to="/admin/st/fuelType" className="nav-link">Fuel Type</Link></li>
                </ul>

                
            </div>

            

            <div className="info-list">
                <label className="sidebar-label pd-x-15 mg-t-25 mg-b-20 tx-info op-9">Information Summary</label>
                <div>
                {this.state.dataStatus}
                </div>
            </div>
            </div>
        );
    }
}

export default SideMenu;