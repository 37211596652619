import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import {leftZero} from "../general/generalJsFunctions";
import AlertMessageBootstrap from "../general/AlertMessageBootstrap";
import MaintFollowup from "./components/MaintFollowup";
import AssetModalParent from '../assets/AssetModalParent';
import format from 'date-format';
import ScheduleMaintenance from "../scheduleMaint/scheduleMaintenance";

require('dotenv').config()
const axios = require("axios");

class MaintForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          maintFollowup: "",
          alertMessage: "",
          //fields
          idShow: "", 
          idAsset: "",
          assetName: "",
          createdIn: "",
          idStatusMaintenance: "",
          status: "",
          idMaintType: "",
          type: "",
          subject: "",
          details: "",
          selectAsset: "",
          labelSelectAsset: "Change Asset",
          RedirectPage: "",
          scheduleMaintenance: "",
          linkSchedule: ""
        };
        this.handleCloseAlert = this.handleCloseAlert.bind(this);  
        this.updateContent = this.updateContent.bind(this);
    }



  handleCloseScheduleMaintenance = () => {
    this.setState({scheduleMaintenance: "" });
  }

  handleScheduleMaintenance = () => {
    this.setState({scheduleMaintenance: <ScheduleMaintenance onClose={this.handleCloseScheduleMaintenance} id={this.state.idAsset} name={this.state.assetName} />});
  }


    handleCloseAlert() {
      this.setState({ alertMessage: false });
    }

    handleCloseParentModule = () => {
      this.setState({selectAsset:false})
    }

    handleParentField = (id, name) => {
      this.setState({selectAsset: false });
      this.setState({
          idAsset: id,
          assetName: `${leftZero(6,id)} -  ${name}`})
    }

    selectAsset = () => {
      this.setState({selectAsset: (<AssetModalParent 
          unmountMe={this.handleCloseParentModule}
          selectAsset={this.handleParentField}
          title="Select an Asset"
      />)})
  }

    handleData = id => {
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}maint/${id}`
      })
        .then(function(response) {
          var { idAsset, idStatusMaintenance, subject, details, createdIn, 
                idMaintType, assetName, status, type
          } = response.data.data.results[0];

          let current_datetime = new Date(createdIn)
          let formatted_date = current_datetime.getDate() + "/" + (current_datetime.getMonth() + 1) + "/" + current_datetime.getFullYear()

          var vIdAsset = ""
          var vAssetName = ""
          if (idAsset) {
              vIdAsset = idAsset
              vAssetName = `${leftZero(6,idAsset)} -  ${assetName}` 
            } else {
              vIdAsset = null
              vAssetName = null
            } 

          self.setState({ 
          idShow: <span>#{leftZero(5,id)}</span>,
          idAsset: vIdAsset,
          assetName: vAssetName,
          createdIn: formatted_date,
          idStatusMaintenance: idStatusMaintenance,
          status: status,
          idMaintType: idMaintType,
          type: type,
          subject: subject,
          details: details,
          maintFollowup: <MaintFollowup id={self.props.match.params.id} updateContent={self.updateContent} /> });
        })
        .catch(function(response) {
          console.log("erro!!")
        });
    }

    handleChange = e => {
      const value = e.target.value;
      const id = e.target.id;
      this.setState({ [id]: [value] });
    };

    goBack() {
      return window.history.go(-1); 
    }

    putData() {
      var self = this;
      axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}maint/${self.props.match.params.id}`,
        data: {
            idAsset: self.state.idAsset,
            subject: self.state.subject,
            details: self.state.details
        }
      })
      .then(function(response) {
        self.setState({alertMessage: <div className="col-lg-12 mt-4"><AlertMessageBootstrap
          messageType="success"
          message={response.data.message}
          unmountMe={self.handleCloseAlert}
        /></div>})
      })
      .catch(function(response) {
        self.setState({alertMessage: <div className="col-lg-12 mt-4"><AlertMessageBootstrap
          messageType="danger"
          message={`Error: ${response}`}
          unmountMe={self.handleCloseAlert}
        /></div>})
      })
    }

    postData() {
      var self = this;
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}maint/${self.props.match.params.id}`,
        data: {
            idAsset: self.state.idAsset,
            subject: self.state.subject,
            details: self.state.details
        }
      })
      .then(function(response) {
       
        if (response.data.success === true) {

          self.setState({ RedirectPage: <Redirect to={`${response.data.id}`} key="1" />,
          maintFollowup: <MaintFollowup id={response.data.id} />,
          createdIn: format('dd/MM/yyyy', new Date()),
          status: "Not touched"
         });

        } else {
          self.setState({alertMessage: <div className="col-lg-12 mt-4"><AlertMessageBootstrap
          messageType="danger"
          message={`Error: ${response}`}
          unmountMe={self.handleCloseAlert}
        /></div>})
        }



      })
      .catch(function(response) {
        self.setState({alertMessage: <div className="col-lg-12 mt-4"><AlertMessageBootstrap
          messageType="danger"
          message={`Error: ${response}`}
          unmountMe={self.handleCloseAlert}
        /></div>})
      })
    }

    submitForm = () => {
      !isNaN(this.props.match.params.id) ? this.putData() : this.postData() ;
    }

    componentDidMount() {
      if (!isNaN(this.props.match.params.id)) { 
        this.handleData(this.props.match.params.id)
        this.setState({linkSchedule: <span
          onClick={this.handleScheduleMaintenance}
          className="badge badge-primary pointer"
        >
          Manage Schedule
        </span>})
         } else {
            this.setState({labelSelectAsset: "Select"});
         }   
    }

    updateContent(id, name) {

      this.setState({
        idStatusMaintenance: id,
        status: name,
      })
    }

    render() {
        return (
    <div className="br-mainpanel">{this.state.RedirectPage}
        <div className="br-pageheader pd-y-15 pd-l-20">
          <nav className="breadcrumb pd-0 mg-0 tx-12">
            <a className="breadcrumb-item" href="index.html">
              Superior Jetties
            </a>
            <span className="breadcrumb-item active">
               Maintenance
            </span>
          </nav>
        </div>

        <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
          <div>
        <h4 className="tx-gray-800 mg-b-5">Maintenance {this.state.idShow}</h4>
          </div>

          <div>
          
          {this.state.scheduleMaintenance}
         


          <span
            className="btn btn-outline-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2"
            onClick={this.goBack}
          >
            Go Back
          </span>
          </div>

        </div>

        <div className="br-pagebody">

          <div className="br-section-wrapper">
            <div className="table-wrapper">
              <div className="form-layout">
                <div className="row mg-b-25">
                  {/* Asset # */}
                  <div className="col-lg-8">
                    <div className="form-group">
                      <label className="form-control-label">
                        Asset # <span className="tx-danger"></span>
                      </label><br/>
                      <h5>{this.state.assetName}  </h5> {this.state.linkSchedule}
                        <span className="badge badge-light pointer" onClick={() => this.selectAsset()}>{this.state.labelSelectAsset}</span>
                      {this.state.selectAsset}
                    </div>
                  </div>

                  {/* Date # */}
                  <div className="col-lg-2">
                    <div className="form-group">
                      <label className="form-control-label">
                        Date<span className="tx-danger"></span>
                      </label><br/>
                      <h5>{this.state.createdIn}</h5>
                    </div>
                  </div>

                  {/* Status # */}
                  <div className="col-lg-2">
                    <div className="form-group">
                      <label className="form-control-label">
                        Status<span className="tx-danger"></span>
                      </label><br/>
                      <h5>{this.state.status}</h5>
                    </div>
                  </div>

                  {/* Subject # */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-control-label">
                        Subject<span className="tx-danger"></span>
                      </label><br/>
                      <input
                        type="text"
                        id="subject"
                        className="w-100"
                        value={this.state.subject}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* Details # */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-control-label">
                      Details<span className="tx-danger"></span>
                      </label><br/>
                      <textarea 
                        rows="5" 
                        className="w-100" 
                        id="details"
                        onChange={this.handleChange} 
                        value={this.state.details} 
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    onClick={this.submitForm}
                  />
                  </div>

                  {this.state.alertMessage}

                </div>
                
                {this.state.maintFollowup}
                <div>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="br-footer">
          <div className="footer-left">
            <div className="mg-b-2">
              Copyright &copy; 2019. Superior Jetties Group. All Rights
              Reserved.
            </div>
            <div>Developed by Media Booth Australia</div>
          </div>
        </footer>
      </div>
        );
    }
}

export default MaintForm;