import React, { Component } from 'react';
import { Link } from "react-router-dom";

require('dotenv').config()
const axios = require("axios");

class AssetPicturesRow extends Component {
    constructor(props) {
        super(props);
        this.state = { 
                        display: "",
                        fileName: "",
                        imageDisplay: ""
                    }              
    }

    componentWillMount() {
        var fileSplit = this.props.imgSrc.split('.');
        var size = fileSplit.length - 1
        switch (fileSplit[size]) {
            case 'jpg':
            case 'png':
                this.setState({
                    fileName: `${process.env.REACT_APP_API_URL}asset-show-file/${this.props.idAsset}/${this.props.imgSrc}`,
                    imageDisplay: `${process.env.REACT_APP_API_URL}asset-show-file/${this.props.idAsset}/${this.props.imgSrc}`
                })
              break;
            case 'pdf':
                this.setState({
                    fileName: `${process.env.REACT_APP_API_URL}asset-show-file/${this.props.idAsset}/${this.props.imgSrc}`,
                    imageDisplay: `/img/pdf-placeholder.jpg`
                })
                break;
            default:
                this.setState({
                    fileName: `${process.env.REACT_APP_API_URL}asset-show-file/${this.props.idAsset}/${this.props.imgSrc}`,
                    imageDisplay: `/img/file-placeholder.jpg`
                })
          }


    }

    handleDelete = () => {
        var self = this;
        axios({
          method: "delete",
          url: `${process.env.REACT_APP_API_URL}asset-delete-file/${self.props.idAsset}/${self.props.imgSrc}`
        })
          .then(() => {
            self.props.reloadPictures()
          })
          .catch(function(response) {
            console.log(response);
          });
        
    }
    
    render() {
        return (
            <div className={`col-md-2  mb-4 text-right ${this.state.display}`}>
            <a href={this.state.fileName} target="_blank" rel="noopener noreferrer"  title={this.state.fileName}>
                    <img src={this.state.imageDisplay} class="img-fluid rounded" alt={this.state.fileName}/>
            </a>
                <Link onClick={() => this.handleDelete()}>
                    <span>Delete</span>
                </Link>
                
            </div>
        );
    }
}

export default AssetPicturesRow;