import React, { Component } from "react";
import { Link } from "react-router-dom";
import SideTableRows from "./sideTable/SideTableRows";
import Pagination from "./general/Pagination";
import NoDataAnswer from "./general/NoDataAnswer";
require('dotenv').config()
const axios = require("axios");

class SideTablePanel extends Component {
  constructor(props) {
    super(props);
    this.loadAssetsData();
    this.changePage = this.changePage.bind(this);
    this.tbName = this.props.match.params.tbName;
    this.state = {
      activePage: 1,
      dataAssets: "",
      pagination: ""
    };
    this.handleChildUnmount = this.handleChildUnmount.bind(this);
  }


  handleChildUnmount(){
    //this.setState({dataAssets: false, pagination: false});
    this.loadAssetsData();
  }



  changePage(page) {
    this.loadAssetsData(page);
  }


  loadAssetsData(start, limit) {
    
    var self = this;
    self.setState({dataAssets: false, pagination: false});

    var vstart = start || 0;
    var vlimit = limit || 10;

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}sidetable/${
        self.props.details.table
      }?start=${vstart}&limit=${vlimit}&q=`
    })
      .then(function(response) {
        var dataAssets = [];
        var assetsList = response.data;
        for (let i = 0; i < assetsList.data.results.length; i++) {
          dataAssets.push(
            <SideTableRows
              data={assetsList.data.results[i]}
              parentpath={self.props.match.path}
              key={assetsList.data.results[i].id}
              table={self.props.details.table}
              unmountMe={self.handleChildUnmount}
            />
          );
        }

        if (dataAssets.length===0){
          dataAssets.push(<NoDataAnswer />);
        }
        self.setState({ dataAssets: dataAssets });
        if (Number(assetsList.additional_data.pagination.limit) <= Number(assetsList.additional_data.pagination.num_records)){
            var compPagination = [];
            compPagination.push(
              <Pagination
                data={assetsList.additional_data.pagination}
                changePage={self.changePage}
              />
            );

            self.setState({ pagination: compPagination });
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  }

  render() {
    return (
      <div className="br-mainpanel">
        <div className="br-pageheader pd-y-15 pd-l-20">
          <nav className="breadcrumb pd-0 mg-0 tx-12">
            <a className="breadcrumb-item" href="index.html">
              Superior Jetties
            </a>
            <a className="breadcrumb-item" href="/">
              Utilities
            </a>
            <span className="breadcrumb-item active">{this.props.details.title}</span>
          </nav>
        </div>

        <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
                <div>
                   <h4 className="tx-gray-800 mg-b-5">{this.props.details.title}</h4>
                </div>
                <Link to={`${this.props.match.path}/new`} className="btn btn-outline-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2">Add New</Link>
        </div>



        <div className="br-pagebody">
          <div className="br-section-wrapper">
            <div className="table-wrapper">
              <table
                id="datatable1"
                className="table display responsive nowrap"
              >
                <thead>
                  <tr>
                    <th className="wd-15p">#</th>
                    <th className="wd-50p">Name</th>
                    <th className="wd-20p">Status</th>
                    <th className="wd-40p">{" "}</th>
                  </tr>
                </thead>{this.state.dataAssets}
              </table>
              {this.state.pagination}
              
            </div>
          </div>
        </div>
        <footer className="br-footer">
          <div className="footer-left">
            <div className="mg-b-2">
              Copyright &copy; 2019. Superior Jetties Group. All Rights
              Reserved.
            </div>
            <div>Developed by Media Booth Australia</div>
          </div>
        </footer>
      </div>
    );
  }
}

export default SideTablePanel;
