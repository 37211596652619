import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class ModalDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {show: true}
    }
    handleClose = () => {
        this.setState({show:false})
        this.props.unmountMe();
    }
    handleShow = () => {
        this.setState({show:true})
    }
    handleDelete = (idRow) => {
        this.setState({show:false})
        this.props.deleteMe(idRow);
    }

    render() {
        
        return (

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Assets Control</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you confirm delete '{this.props.name}'?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => this.handleDelete(this.props.idRow)}>
                        Delete
                    </Button>
                    </Modal.Footer>
                </Modal>


        );
    }
}

export default ModalDialog;