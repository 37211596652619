import React, { Component } from "react";
import { Link } from "react-router-dom";
import AssetRows from "./assets/AssetRows";
import AssetsStatus from "./assets/AssetsStatus";
import Pagination from "./general/Pagination";
import NoDataAnswer from "./general/NoDataAnswer";
import AssetFilter from "./assets/AssetFilter";
import createHistory from "history/createBrowserHistory";



const history = createHistory();

require('dotenv').config()
const axios = require("axios");
 
class AssetPanel extends Component {
  constructor(props) {
    super(props);
   
    this.changePage = this.changePage.bind(this);
    this.state = {
      activePage: 1,
      dataStatus: "",
      dataAssets: "",
      pagination: "",
      filterStatus: "",
      filterStringQuery: "",
      filterCategory: "",
      filterCompany:"",
      filterLocation:"",
      preparingDownload:"d-none",
      startDownload:"d-none",
      exportXlsx:"",
      downloadXlsxLink:""
    };
    this.handleChildUnmount = this.handleChildUnmount.bind(this);
    this.filterChanged = this.filterChanged.bind(this);

    this.loadAssetsData();
    this.loadStatusData();

  }

  loadStatusData() {
    var self = this;
    var dataHtml = [];
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}asset/status`
    })
      .then(function(response) {
        var statusList = response.data.result.data.results;
        for (let i = 0; i < statusList.length; i++) {
          dataHtml.push(
            <AssetsStatus
              title={statusList[i].name}
              value={statusList[i].total}
              color={statusList[i].color}
              icon={statusList[i].icon}
              idStatus={statusList[i].id}
              key={statusList[i].id}
              handleChildUnmount={self.handleChildUnmount}
            />
          );
        }
        self.setState({ dataStatus: dataHtml });
      })
      .catch(function(response) {
        console.log(response);
      });
  }


  handleChildUnmount(vstatus){
    this.setState({
      filterStringQuery: "",
      filterStatus: vstatus,
      filterCategory:  "",
      filterCompany: "",
      filterLocation: ""
    });     

this.loadAssetsData()   
  }

  changePage = page => {
    console.log("cPage", page)
   // page.preventDefault();
   var urlParams = `?filterStatus=&filterStringQuery=&filterCategory=&filterCompany=&filterLocation=&page=${page}`
   history.push(`/admin/assets/${urlParams}`);
   // console.log(this)
    this.loadAssetsData(page);
  }

  loadAssetsData(start, limit) {
    var self = this;
    self.setState({dataAssets: false, pagination: false});

    var vstart = start || 0;
    var vlimit = limit || 50;

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}asset?start=${vstart}&limit=${vlimit}&q=${this.state.filterStringQuery}&filterStatus=${this.state.filterStatus}&filterCategory=${this.state.filterCategory}&filterCompany=${this.state.filterCompany}&filterLocation=${this.state.filterLocation}`
    })
      .then(function(response) {
        var dataAssets = [];
        var assetsList = response.data;
        for (let i = 0; i < assetsList.data.results.length; i++) {
          dataAssets.push(
            <AssetRows
              data={assetsList.data.results[i]}
              parentpath=""
              key={assetsList.data.results[i].id} 
              table="company"
              unmountMe={self.handleChildUnmount}
            />
          );
        }



        if (dataAssets.length===0){
          dataAssets.push(<NoDataAnswer />);
        }
        self.setState({ dataAssets: dataAssets });
        if (Number(assetsList.additional_data.pagination.limit) <= Number(assetsList.additional_data.pagination.num_records)){
            var compPagination = [];
            compPagination.push(
              <Pagination
                data={assetsList.additional_data.pagination}
                changePage={self.changePage}
              />
            );

            self.setState({ pagination: compPagination });
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  }

  filterChanged(vquery, vstatus, vcategory, vcompany, vlocation) {
    //console.log("-vquery- " + vquery)
    this.setState({
                      filterStringQuery: vquery,
                      filterStatus: vstatus,
                      filterCategory: vcategory,
                      filterCompany: vcompany,
                      filterLocation: vlocation,
                    });     

    this.loadAssetsData()                
  }

  downloadXlsx = () => {
    console.log(`${process.env.REACT_APP_API_URL}${this.state.downloadXlsxLink}`)
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}${this.state.downloadXlsxLink}`
    })
      .then(function(response) {
        console.log("done")
      })
  }

  createXlsx = () => {
    var self = this;
    self.setState({preparingDownload:"",
                    startDownload:"d-none",
                    exportXlsx:"d-none"})
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}asset2xlsx?q=${this.state.filterStringQuery}&filterStatus=${this.state.filterStatus}&filterCategory=${this.state.filterCategory}&filterCompany=${this.state.filterCompany}&filterLocation=${this.state.filterLocation}` 
    })
    .then(function(response) {
      console.log("download")
      console.log(response.data.msg)
      
      self.setState({preparingDownload:"d-none",
                     startDownload:"",
                      exportXlsx:"d-none",
                      downloadXlsxLink: response.data.msg
                    })
      
    })
    .catch(function(response) {
      console.log(response);
    });
  }

  render() {
    return (
      <div className="br-mainpanel">
        <div className="br-pageheader pd-y-15 pd-l-20">
          <nav className="breadcrumb pd-0 mg-0 tx-12">
            <a className="breadcrumb-item" href="index.html">
              Superior Jetties
            </a>
            <a className="breadcrumb-item" href="/">
              Utilities
            </a>
            <span className="breadcrumb-item active">Assets</span>
          </nav>
        </div>

        <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
                <div>
                   <h4 className="tx-gray-800 mg-b-5">Assets</h4>
                </div>

                <div>
                
                <Link onClick={() => this.createXlsx()} className={`btn btn-outline-success btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2 mr-4 ${this.state.exportXlsx}`}>Export Xlsx</Link>
                <a href={process.env.REACT_APP_API_URL + this.state.downloadXlsxLink} className={`btn btn-outline-success btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2 mr-4 ${this.state.startDownload}`}  target="_blank" rel="noopener noreferrer" download>Start Download</a>
                <Link className={`btn btn-outline-success btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2 mr-4 ${this.state.preparingDownload}`}><img srcset="/img/loading_spinner.gif" width={`15px`} alt=""></img></Link>
                
                <Link to={`/admin/assets/new`} className="btn btn-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2">Add New</Link>
                </div>
                
        </div>

        

        <div className="br-pagebody">
        <div className="row row-sm mb-4">{this.state.dataStatus}</div>
        
          <div className="br-section-wrapper">
            <AssetFilter
              filterChanged={this.filterChanged}
            />
            <div className="table-wrapper">
              <table
                id="datatable1"
                className="table display responsive nowrap"
              >
                <thead>
                  <tr>
                    <th className="wd-5p">#</th>
                    <th className="wd-30p">Asset</th>
                    <th className="wd-20p">Location</th>
                    <th className="wd-20p">Category</th>
                    <th className="wd-15p">Status</th>
                    <th className="wd-35p">{" "}</th>
                  </tr>
                </thead>{this.state.dataAssets}
              </table>
              {this.state.pagination}
            </div>
          </div>
        </div>
        <footer className="br-footer">
          <div className="footer-left">
            <div className="mg-b-2">
              Copyright &copy; 2019. Superior Jetties Group. All Rights
              Reserved.
            </div>
            <div>Developed by Media Booth Australia</div>
          </div>
        </footer>
      </div>
    );
  }
}

export default AssetPanel;
