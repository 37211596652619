import React, { Component } from 'react';

class scheduleMaintenanceRow extends Component {
    render() {
        return (
            <tr>
                <td>Every {this.props.row.freq} {this.props.row.period}</td>
                <td>{this.props.row.description}</td>
                <td>{this.props.row.firstMaint}</td>
                <td><button type="button" class="btn btn-danger p-1" onClick={() => this.props.handleDeleteModal(this.props.row.id)}>Delete</button></td>
            </tr>
        );
    }
}

export default scheduleMaintenanceRow;