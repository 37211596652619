import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import localStorage from 'localStorage';

import Logo from "./components/Logo";
import SideMenu from "./components/SideMenu";
import Header from "./components/Header";
import AssetPanel from "./components/AssetPanel";
import AssetForm from "./components/assets/AssetForm";
import SideTablePanel from "./components/SideTablePanel";
import SideTableForm from "./components/sideTable/SideTableForm";
import DashboardPanel from "./components/DashboardPanel";
import Maintenance from "./components/maintenance/MaintenancePanel";
import MaintForm from "./components/maintenance/MaintForm";
import MaintenanceInbox from "./components/maintenance-inbox/MaintenanceInbox";

class MainControl extends Component {
  constructor(props) {
    super(props);
    this.state = {RedirectPage: ""};
    this.loginAuthentication();
  }
  
  loginAuthentication() {
    if (localStorage.getItem('userFirstName') === null) {
      window.location.href = "/login";
    } 
   }

  render() {
    return (
      <Router>
        <div className="App">
          <Logo />
          <SideMenu />
          <Header />
          <Switch>
            <Route
              exact
              path="/admin/assets"
              component={() => (
                <AssetPanel userDetails={this.state.userDetails} />
              )}
              key="1"
            />
            <Route 
              exact
              path="/admin/assets/:id" 
              render={(props) => <AssetForm {...props} details={{table:"asset", title:"Asset"}} key="11" />}
            />

            <Route
              exact
              path="/admin/dashboard"
              component={() => (
                <DashboardPanel userDetails={this.state.userDetails} />
              )}
              key="2"
            />


            {/* Utilities */}
            <Route 
              exact
              path="/admin/st/company" 
              render={(props) => <SideTablePanel {...props} details={{table:"company", title:"Company"}} key="3" />}
            />
            <Route 
              exact
              path="/admin/st/company/:id" 
              render={(props) => <SideTableForm {...props} details={{table:"company", title:"Company"}} key="33" />}
            />

            <Route 
              exact
              path="/admin/st/location" 
              render={(props) => <SideTablePanel {...props} details={{table:"location", title:"Location"}} key="4" />}
            />
            <Route 
              exact
              path="/admin/st/location/:id" 
              render={(props) => <SideTableForm {...props} details={{table:"location", title:"Location"}} key="44" />}
            />

            <Route 
              exact
              path="/admin/st/category" 
              render={(props) => <SideTablePanel {...props} details={{table:"assetCategory", title:"Category"}} key="5" />}
            />
            <Route 
              exact
              path="/admin/st/category/:id" 
              render={(props) => <SideTableForm {...props} details={{table:"assetCategory", title:"Category"}} key="55" />}
            />




            <Route 
              exact
              path="/admin/st/brand" 
              render={(props) => <SideTablePanel {...props} details={{table:"assetBrand", title:"Brand"}} key="6" />}
            />
            <Route 
              exact
              path="/admin/st/brand/:id" 
              render={(props) => <SideTableForm {...props} details={{table:"assetBrand", title:"Brand"}} key="66" />}
            />


            <Route 
              exact
              path="/admin/st/condition" 
              render={(props) => <SideTablePanel {...props} details={{table:"assetCondition", title:"Condition"}} key="7" />}
            />
            <Route 
              exact
              path="/admin/st/condition/:id" 
              render={(props) => <SideTableForm {...props} details={{table:"assetCondition", title:"Condition"}} key="77" />}
            />


            <Route 
              exact
              path="/admin/st/area" 
              render={(props) => <SideTablePanel {...props} details={{table:"assetArea", title:"Area"}} key="8" />}
            />
            <Route 
              exact
              path="/admin/st/area/:id" 
              render={(props) => <SideTableForm {...props} details={{table:"assetArea", title:"Area"}} key="88" />}
            />

            <Route 
              exact
              path="/admin/st/fuelType" 
              render={(props) => <SideTablePanel {...props} details={{table:"fuelType", title:"Fuel Type"}} key="9" />}
            />
            <Route 
              exact
              path="/admin/st/fuelType/:id" 
              render={(props) => <SideTableForm {...props} details={{table:"fuelType", title:"Fuel Type"}} key="99" />}
            />

            <Route 
              exact
              path="/admin/maintenance/inbox"
              render={(props) => <MaintenanceInbox {...props} userDetails={this.state.userDetails} />}
            />

            <Route 
              exact
              path="/admin/maintenance/"
              render={(props) => <Maintenance {...props} userDetails={this.state.userDetails} />}
            />

            <Route 
              exact
              path="/admin/maintenance/form/:id"
              render={(props) => <MaintForm {...props} userDetails={this.state.userDetails} />}
            />



           
          </Switch>
        </div>
        
      </Router>
    );
  }
}

export default MainControl;
