import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import AssetModalParentRows from './AssetModalParentRows';

require('dotenv').config()
const axios = require("axios");

class AssetModalParent extends Component {
    constructor(props) {
        super(props);
        this.state = {
                        show: true,
                        showDataAssets: false,
                        searchFor: ""
                    }
        this.loadAssetsData()     
        this.handleSelect = this.handleSelect.bind(this);        
    }
    handleClose = () => {
        this.props.unmountMe()
    }
    handleShow = () => {
        this.setState({show:true})
    }

    handleSelect = (id, name) => {
        this.props.selectAsset(id, name)
    }



    handleChange = e => {
        const value = e.target.value;
        this.setState({ searchFor: [value] });
        setTimeout(() => {
          this.loadAssetsData();
        }, 0); 
    };

    loadAssetsData() {
        var self = this;
       
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}asset?start=0&limit=5&q=${self.state.searchFor}`
        })
          .then(function(response) {
            var dataAssets = [];
            var assetsList = response.data;
            for (let i = 0; i < assetsList.data.results.length; i++) {
              dataAssets.push(
                <AssetModalParentRows
                  data={assetsList.data.results[i]}
                  parentpath=""
                  key={assetsList.data.results[i].id} 
                  unmountMe={self.handleChildUnmount}
                  selectItem={self.handleSelect}
                />
              );
            }
            self.setState({showDataAssets: dataAssets});
          })
          .catch(function(response) {
            console.log(response);
          });
      }
   
    render() {
        
        return (

            <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <table class="table table-bordered mg-b-0">
            <thead>
                <tr>
                <th>ID</th>
                <th>AKA</th>
                <th>Name</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {this.state.showDataAssets}
            </tbody>
            </table>    

            </Modal.Body>
            <Modal.Footer>
            <div class="col-lg-12 mg-t-20 mg-lg-t-0">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search for..." onChange={this.handleChange} />
                  <span class="input-group-btn">
                    <button class="btn bd bg-white tx-gray-600" type="button"><i class="fa fa-search"></i></button>
                  </span>
                </div>
              </div>

            
            </Modal.Footer>
        </Modal>


        );
    }
}

export default AssetModalParent;