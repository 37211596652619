import React, { Component } from 'react';
import SelectOptionItem from "../general/SelectOptionItem";

require('dotenv').config()
const axios = require("axios");

class AssetFilter extends Component {
    constructor(props) {
       super(props);
       this.state = {
        assetCategory: "",
        idAssetCategoryOption: "",
        location: "",
        idLocation: "",
        idLocationOption: "",
        idAssetStatus: "",
        idCompanyOption: "",
        idCompany: "",
        idAssetStatusOption: "",
        stringQuery: "",
        idAssetCategory:""
       }

       this.getSelectOptionData("assetCategory", "idAssetCategoryOption");
       this.getSelectOptionData("location", "idLocationOption");
       this.getSelectOptionData("company", "idCompanyOption");
       this.getSelectOptionData("assetStatus", "idAssetStatusOption");

    }
    

    getSelectOptionData(table, selectId) {
        var self = this;
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}sidetable/${table}?status=1&limit=1000`
        })
          .then(function(response) {
            var selectComponent = [];
            var selectData = response.data.data.results;
            for (let i = 0; i < selectData.length; i++) {
              //console.log(" id= " + selectData[i].id + " name: " + selectData[i].name);
              selectComponent.push(
                <SelectOptionItem
                  id={selectData[i].id}
                  name={selectData[i].name}
                  key={`${selectId}${selectData[i].id}`}
                />
              );
            }
    
            self.setState({ [selectId]: selectComponent });
          })
          .catch(function(response) {
            console.log(response);
          });
    }

    handleChange = e => {
        const value = e.target.value;
        const id = e.target.id;
        this.setState({ [id]: [value] });
        //console.log(id, value)
        setTimeout(() => {
          this.props.filterChanged(this.state.stringQuery, this.state.idAssetStatus, this.state.idAssetCategory, this.state.idCompany, this.state.idLocation  );
        }, 0);
          
    };

    render() {
        return (
        <div className="d-flex bg-gray-100 align-items-center ht-md-80 bd pd-x-20 mb-5">
            <div className="col-lg-3">
                <input type="text" id="stringQuery" className="form-control" placeholder="Asset, Serial..."   onChange={this.handleChange}/>
            </div>
            <div className="col-lg-2"> 
              <select className="form-control ml-1"
                        id="idAssetCategory"
                        value={this.state.idAssetCategory}
                        onChange={this.handleChange}
                      >
                        <option value="">Category</option>
                        {this.state.idAssetCategoryOption}
              </select>
            </div>  
            <div className="col-lg-2">  
            <select className="form-control ml-1"
                        id="idLocation"
                        value={this.state.idLocation}
                        onChange={this.handleChange}
                      >
                        <option value="">Location</option>
                        {this.state.idLocationOption}
              </select>
            </div>
            <div className="col-lg-2">
            <select className="form-control ml-1"
                        id="idCompany"
                        value={this.state.idCompany}
                        onChange={this.handleChange}
                      >
                        <option value="">Company</option>
                        {this.state.idCompanyOption}
              </select>
            

            </div>

            <div className="col-lg-2">
            <select className="form-control ml-1"
                        id="idAssetStatus"
                        value={this.state.idAssetStatus}
                        onChange={this.handleChange}
                      >
                        <option value="">Status</option>
                        {this.state.idAssetStatusOption}
              </select>
            </div>

            <div className="col-lg-2">
            {/* <a class="btn btn-primary btn-oblong tx-9 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-10 bd-2" onClick={() => this.searchForm()} >Search</a> */}
            </div>
    
        </div>
        );
    }
}

export default AssetFilter;