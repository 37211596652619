import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class AssetsStatus extends Component {
    clickHandler = itemValue => {
      setTimeout(() => {
        this.props.handleChildUnmount(itemValue); 
      }, 0);     
    };
    render() {
        return (
            <div className="col-sm-6 col-xl-4 mb-3">
              <div className={`${this.props.color} rounded overflow-hidden`}>
                <div className="pd-25 d-flex align-items-center">
                  <i 
                  className={`ion ${this.props.icon} tx-60 lh-0 tx-white op-7`}></i>
                  <div className="mg-l-20">
                  
                    <p className="tx-10 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-white-8 mg-b-10"><Link className="text-light" onClick={() => this.clickHandler(this.props.idStatus)}>{this.props.title}</Link></p>
                    <p className="tx-24 tx-white tx-lato tx-bold mg-b-2 lh-1"><Link className="text-light" onClick={() => this.clickHandler(this.props.idStatus)}>{this.props.value}</Link></p>                  
                  </div>
  
                </div>
              </div>
            </div>
        );
    }
}

export default AssetsStatus;