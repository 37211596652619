import React, { Component } from 'react';
import { Link } from "react-router-dom";
import AssetPicturesRow from "./AssetPicturesRow";
import AlertMessageBootstrap from "../general/AlertMessageBootstrap";


require('dotenv').config()
const axios = require("axios");

class AssetPictures extends Component {
    constructor(props) {
        super(props);
        this.state = { 
                        show: "d-none", //"d-none"
                        showAll: false,
                        showHide: "d-none", //"d-none"
                        showChildListRow: false,
                        qtdePictures: 0,
                        listOfPictures: [],
                        showAssetPicturesRow: false,
                        selectedFiles: null,
                        alertMessage: null
                    }   
        this.handleCloseAlert = this.handleCloseAlert.bind(this);    
        this.handReloadPictures = this.handReloadPictures.bind(this);             
    }
   
    handReloadPictures() {
      this.setState({ qtdePictures: 0});
      this.setState({listOfPictures: []})
      this.handleData()
        setTimeout(() => {
          this.printPictures()
        }, 1000);
    }
    handleCloseAlert() {
      this.setState({ alertMessage: false });
    }

    componentDidMount() {
      this.handleData()
    }

    printPictures() {
      var picturesElements=[];
      for (let i = 0; i < this.state.listOfPictures.length; i++) {
        picturesElements.push(<AssetPicturesRow idAsset={this.props.idAsset} imgSrc={this.state.listOfPictures[i]} reloadPictures={this.handReloadPictures}/>);
      }
      this.setState({showAssetPicturesRow: picturesElements})
    }

    handleShow = () => {
        if(this.state.show==="d-none") {
            this.setState({show: false, showAll: "d-none", showHide: false})
            this.printPictures()
        } else {
            this.setState({show: "d-none", showAll: false, showHide: "d-none"})
        }
    }

    handleData = () => {

      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}asset-file/${self.props.idAsset}/${self.props.type}`
      })
        .then(function(response) {
          var selectData = response.data.data.picture;
          self.setState({ qtdePictures: selectData.length});
          self.setState({listOfPictures: selectData})
        })
        .catch(function(response) {
          console.log(response);
        });
    }

    handleSubmitImages = event => {
      var listOfSelectedFiles = [];
      for (let i = 0; i < event.target.files.length; i++) {
        listOfSelectedFiles.push(event.target.files[i])
        
      }
      this.setState({selectedFiles: listOfSelectedFiles})
      
    }

    handleUploadImages = () => {
      const data = new FormData() 
      for(var x = 0; x<this.state.selectedFiles.length; x++) {
        data.append('file', this.state.selectedFiles[x])
       }

      axios.post(`${process.env.REACT_APP_API_URL}asset-upload/${this.props.idAsset}/${this.props.type}`, data, { // receive two parameter endpoint url ,form data 
      })
      .then(res => { // then print response status
        this.setState({alertMessage: <AlertMessageBootstrap messageType="success" message="The upload has been done!" unmountMe={this.handleCloseAlert}/>})
        this.setState({ qtdePictures: 0});
        this.setState({listOfPictures: []})
        this.handleData()
        setTimeout(() => {
          this.printPictures()
        }, 1000);
        
      })
    }

    render() {
        return (
            <div className="col-lg-12 mt-4">
            <div className="card shadow-base bd-0">
              <div className="card-header bg-info pd-20">
                <h6 className="card-title tx-uppercase tx-12 mg-b-0 text-white">{this.props.title} - {this.state.qtdePictures} File(s)</h6>
              </div>
              
              <div className={this.state.show}>
                <div class={`row p-5`}>
                  {this.state.showAssetPicturesRow}

                  
                </div>

                <div className="form-upload-files">
                  <input type="file" name="img" multiple className="" onChange={this.handleSubmitImages}/><br/>
                  <input type="submit"  className="mt-2 btn btn-primary" onClick={this.handleUploadImages}/>
                  
                </div>
                <div className="p-4">{this.state.alertMessage}</div>
              </div>


              <div className="card-footer tx-12 pd-y-15 bg-transparent">
                <Link onClick={() => this.handleShow()}>
                    <span className={this.state.showAll}><i className="fa fa-angle-down mg-r-5"></i>Show All Pictures</span>
                    <span className={this.state.showHide}><i className="fa fa-angle-up mg-r-5"></i>Hide All Pictures</span>
                </Link>                
              </div>
            </div>
          </div>
        );
    }
}

export default AssetPictures;