import React, { Component } from "react";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = { dataPagination: "" };
    this.loadPagination(this.props);
  }

  changePageLi = page => {
    this.props.changePage(page);
  };

  loadPagination(addition_data) {
    var dataHtmlPagination = [];
    var totalPages =
    Number(addition_data.data.num_records) / Number(addition_data.data.limit);
    if (addition_data.data.start >> 0) {
      dataHtmlPagination.push(
        <LiPagination
          item={Number(addition_data.data.start - addition_data.data.limit)}
          key={Number(addition_data.data.start - addition_data.data.limit)}
          text="<<"
          changePageLi={this.changePageLi}
        />
      );
    }

    for (let index = 0; index < Number(totalPages); index++) {
      var strong = "0";
      if (Number(index) === Number(addition_data.data.start/addition_data.data.limit)) {
        strong = "1"
      }
      dataHtmlPagination.push(
        <LiPagination
          item={Number(addition_data.data.limit * index)}
          key={Number(addition_data.data.limit * index)}
          text={Number(index + 1)}
          strong={strong}
          changePageLi={this.changePageLi}
        />
      );
    }
    if (
      Number(addition_data.data.start * addition_data.data.limit) <=
      Number(addition_data.data.num_records)
    ) {
      dataHtmlPagination.push(
        <LiPagination
          item={Number(addition_data.data.start + addition_data.data.limit)}
          key={Number(addition_data.data.start + addition_data.data.limit)}
          text=">>"
          changePageLi={this.changePageLi}
        />
      );
    }

    this.setState({ dataPagination: dataHtmlPagination });
  }

  componentDidMount() {
    this.loadPagination(this.props);
  }

  render() {
    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination">{this.state.dataPagination}</ul>
      </nav>
    );
  }
}

export default Pagination;

class LiPagination extends Component {
  clickHandler = () => {
    this.props.changePageLi(this.props.item);
  };
  render() {
    return (
      <li className="page-item">
        {" "}
        <button className="page-link" onClick={this.clickHandler}>
          {this.props.text}
        </button>
      </li>
          );
  }
}
