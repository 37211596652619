import React, { Component } from 'react';
import SelectOptionItem from "../../general/SelectOptionItem";
import MaintTimeline from './MaintTimeline';
import AlertMessageBootstrap from "../../general/AlertMessageBootstrap";

require('dotenv').config()
const axios = require("axios");

class MaintFollowup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idStatusMaintenanceOption: false,
            alertMessage: "",
            //fields
            description: "",
            idStatusMaintenance: "",
            nameStatusMaintenance: "",
            selectedFiles: "" ,
            mainttimeline: <MaintTimeline id={this.props.id} />

        } 
        this.getSelectOptionData("maintStatus", "idStatusMaintenanceOption");
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }


    handleCloseAlert() {
        this.setState({
            alertMessage: false})
    }


    
  getSelectOptionData(table, selectId) {
    var self = this;
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}sidetable/${table}?status=1&limit=1000`
    })
      .then(function(response) {
        var selectComponent = [];
        var selectData = response.data.data.results;
        for (let i = 0; i < selectData.length; i++) {
          selectComponent.push(
            <SelectOptionItem
              id={selectData[i].id}
              name={selectData[i].name}
              key={`${selectId}${selectData[i].id}`}
            />
          );
        }
        self.setState({ [selectId]: selectComponent });
      })
      .catch(function(response) {
        console.log(response);
        var CAesslertMessage = [];
        CAesslertMessage.push(
          <AlertMessageBootstrap
            messageType="danger"
            message={`getSelectOptionData failure! (${response})`}
            unmountMe={self.handleCloseAlert}
          />
        );
        self.setState({ AlertMessage: CAesslertMessage });
      });
  }

  handleChange = e => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: [value] });
    if (id === "idStatusMaintenance") {
      this.setState({nameStatusMaintenance: e.target[e.target.selectedIndex].text})
    }

  };

  handleSubmitImages = event => {
    var listOfSelectedFiles = [];
    for (let i = 0; i < event.target.files.length; i++) {
      listOfSelectedFiles.push(event.target.files[i])
      
    }
    this.setState({selectedFiles: listOfSelectedFiles})
  }

  submitForm = () => {
    this.setState({mainttimeline: ""})
    var self = this;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/maint/timeline/${self.props.id}`,
      data: {
          idMaint: self.props.id,
          description: self.state.description,
          idMaintStatus: self.state.idStatusMaintenance,
          createdBy: 1
      }
    })
      .then(function(response) {
        console.log("firstPart", response)
        const dataImages = new FormData()       
        if (self.state.selectedFiles !== null) {
           for(var x = 0; x<self.state.selectedFiles.length; x++) {
               dataImages.append('file', self.state.selectedFiles[x])
            }
       }
        
        if (self.state.selectedFiles.length !== 0) {
            axios.post(`${process.env.REACT_APP_API_URL}maint/timeline/${self.props.id}/${response.data.id}/upload`, dataImages, { // receive two parameter endpoint url ,form data 
            })
            .then(() => {
                self.setState({
                    alertMessage: <AlertMessageBootstrap messageType="success"
                    message={`Your follow-up has been registered!`}
                    unmountMe={self.handleCloseAlert}
                    />,
                    mainttimeline: <MaintTimeline id={self.props.id} />
                })
                self.props.updateContent(self.state.idStatusMaintenance, self.state.nameStatusMaintenance);
            })
            .catch(response => {
                self.setState({
                    alertMessage: <AlertMessageBootstrap messageType="danger"
                    message={`Failed!`}
                    unmountMe={self.handleCloseAlert}
                    />,
                    mainttimeline: <MaintTimeline id={self.props.id} />
                })
            })
          } else {
                self.setState({
                    alertMessage: <AlertMessageBootstrap messageType="success"
                    message={`Your follow-up has been registered!`}
                    unmountMe={self.handleCloseAlert}
                    />,
                    mainttimeline: <MaintTimeline id={self.props.id} />
                })
                self.props.updateContent(self.state.idStatusMaintenance, self.state.nameStatusMaintenance);
            }
            
       



      })
      .catch(function(response) {
        console.log(response);
      });
  }


    render() {
        return (
            <div className="row mg-b-25">
                <div className="col-lg-12 mb-4">
                    <hr />
                </div>
                <div className="col-lg-8"><h5>Timeline</h5>
                    <table class="table table-responsive mg-b-0 tx-12">
                        <thead>
                            <tr class="tx-10">
                                <th class="wd-10p pd-y-5"></th>
                                <th class="pd-y-5"></th>
                            </tr>
                        </thead>
                        {this.state.mainttimeline}
                    </table> 
                </div>

                  <div className="col-lg-4">
                  <h5>Add a Follow-Up</h5>
                    <textarea rows="5" className="w-100 mb-1"
                      placeholder="" id="description" onChange={this.handleChange} value={this.state.description} />
                    <select
                        className={'form-control mb-2'}
                        id="idStatusMaintenance"
                        value={this.state.idStatusMaintenance}
                        onChange={this.handleChange}
                      >
                        <option></option>
                        {this.state.idStatusMaintenanceOption}
                    </select>
                    <input type="file" name="img" class="mb-2"  onChange={this.handleSubmitImages}  multiple/>  
                     <input
                        type="submit"
                        className="btn btn-outline-primary tx-11 tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2 w-100"
                        onClick={this.submitForm}
                  />
                  {this.state.alertMessage}
                  </div>
            </div>
        );
    }
}

export default MaintFollowup;