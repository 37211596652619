import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {leftZero} from "../general/generalJsFunctions";

class AssetChildListRow extends Component {
    constructor(props) {
        super(props);
        this.state = { 
                        assetFormatedId: leftZero(6,this.props.dataRow.id)
                    }   
    }
    render() {
        return (
            <tr>
                    <td>
                      <Link to={`/admin/assets/${this.props.dataRow.id}`} target="_blank" className="tx-inverse tx-14 tx-medium d-block">{this.props.dataRow.name}</Link>
                      <span className="tx-11 d-block">ID: {this.state.assetFormatedId} | AKA: {this.props.dataRow.aka}</span>
                    </td>
                    <td className="tx-12">
                    {this.props.dataRow.category}
                    </td>
                    <td><span className={`square-8 ${this.props.dataRow.color} mg-r-5 rounded-circle`}></span> {this.props.dataRow.status}</td>
            </tr>
        );
    }
}

export default AssetChildListRow;