import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import ScheduleMaintenanceRow from "./scheduleMaintenanceRow"


require('dotenv').config()
const axios = require("axios");


const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });
  
  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2)
    }
  }))(MuiDialogContent);
  
  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1)
    }
  }))(MuiDialogActions);
  
  

class scheduleMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
         open: true,
         freq: 1,
         period: "dd",
         description: "",
         firstM: "",
         rows: [],
         saveDisable: true
        };
      }


      handleDeleteModal = (id) => {

        
        
        var self = this;
        axios({
            method: "delete",
            url: `${process.env.REACT_APP_API_URL}scheduled/manage/${id}`
          })
            .then(function(response) {
              
                const rows = self.state.rows.filter(row => row.id !== id);
                self.setState({ rows })    

            })
            .catch(function(response) {
              console.log(response);
            });

      }

      handleChange = e => {
        const value = e.target.value;
        const id = e.target.id;

        this.setState({ [id]: [value] });
        //console.log(this.state.meterHrs)
        setTimeout(() => {
            console.log("isNaN(this.state.freq)", this.state.freq)
            console.log("this.state.description.length", this.state.description.toString().length)
            console.log("this.state.description.length ", this.state.firstM.toString().length )

            if (this.state.freq.toString().length > 0 && this.state.description.toString().length > 3 && this.state.firstM.toString().length > 7) {
                this.setState({ saveDisable: false })
            } else {
                this.setState({ saveDisable: true })
            }
        });
        

      };

      handleAdd = () => {
        var self = this;
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}scheduled/list/${self.props.id}`,
            data: {idAsset: self.props.id, firstMaint: self.state.firstM, freq: self.state.freq, period: this.state.period, description: this.state.description}
          })
            .then(function(response) {
                self.handleData()
            })
            .catch(function(response) {
                console.log(response)
              return "<span>failure</span>";
            });

      }

    handleClickOpen = () => {
      this.setState({open: true});
    };
    
    handleClose = () => {
      this.setState({open: false});
      this.props.onClose()
    };

    handleData = () => {
        var self = this;
        
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}scheduled/list/${self.props.id}`
          })
            .then(function(response) {
            console.log("length", response)
              if (response.data.results.formatedResults.length === 0) {
                return self.setState({rows: [] });
              }
              self.setState({rows: response.data.results.formatedResults });

            })
            .catch(function(response) {
                console.log("length1", response)
              return "<span>failure</span>";
            });
    }


    componentDidMount() {
        this.handleClickOpen()
        this.handleData()
    }

    render() {
        return (
            <div>
                <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.open}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Schedule Maintenance
                    </DialogTitle>



                    <DialogContent>
                    <div class="d-table bg-gray-100 bd p-3">
                        <div className="row">
                        <div className="col-lg-12">
                                <label class="form-control-label text-muted">Asset</label>
                                <h4>{this.props.name}</h4>
                        </div>
                            <div className="col-lg-6">
                                <label class="form-control-label text-muted">Frequency</label>
                                <input type="number" id="freq" class="form-control" value={this.state.freq} onChange={this.handleChange} /></div>
                            <div className="col-lg-6">
                                <label class="form-control-label">&nbsp;</label>
                                <select class="form-control" id="period" onChange={this.handleChange}>
                                    <option value="dd">Day</option>
                                    <option value="mm">Month</option>
                                    <option value="yy">Year</option>
                                </select>  
                            </div>
                            <div className="col-lg-12  mt-2">
                                <input type="text" id="description" className="form-control" value={this.state.description} onChange={this.handleChange} placeholder="Description..." maxLength="255" />
                            </div>
                            <div className="col-lg-12">
                                <label class="form-control-label  mt-2 text-muted">First Maintenance</label>
                                <input type="date" class="form-control" id="firstM" value={this.state.firstM} onChange={this.handleChange} />
                            </div>
                            <div className="col-lg-12"><button type="button" class="btn btn-success mt-2 w-100" disabled={this.state.saveDisable} onClick={() => this.handleAdd()}>Add New</button></div>
                        </div>
                    </div>  
                    <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">Frequency</th>
                            <th scope="col">Description</th>
                            <th scope="col">First Time</th>
                            <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            
                                {this.state.rows.length > 0 ? this.state.rows.map(rows => <ScheduleMaintenanceRow key={rows.id} row={rows} handleDeleteModal={this.handleDeleteModal} />):<td colSpan="4">There is no maintenance scheduled to list!</td>}
                            
                        </tbody>
                        </table>
                    

                    </DialogContent>
                    <DialogActions>

                    
                    

                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default scheduleMaintenance;


