import React, { Component } from 'react';

class AssetsStatusMenu extends Component {
    render() {
        return (
          <div className="d-flex align-items-center justify-content-between pd-x-15 mt-3">
          <div>
              <p className="tx-10 tx-roboto tx-uppercase tx-spacing-1 tx-white op-3 mg-b-2 space-nowrap">Assets {this.props.title}</p>
              <h5 className="tx-lato tx-white tx-normal mg-b-0">{this.props.value}</h5>
          </div>
          <span><i className={`ion ${this.props.icon} tx-30 lh-0 tx-white op-7`}></i></span>
          </div>      
        );
    }
}

export default AssetsStatusMenu;