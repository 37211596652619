import React, { Component } from 'react';
import { Link } from "react-router-dom";

require('dotenv').config()
const axios = require("axios");

class InboxMainPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showImages: false,
            showMap: false,
            showMapCSSControl: "col-12",
            redirect: false,
            showActionButtons: false,
            recurrent: ""
        }
    }

    getImages = () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}maintenanceRequest/${this.props.data.id}/upload`
        })
        .then(response => {
            if (response.data.success === "false") {
                this.setState({
                    showImages: <span>Database Error!</span>
                })
                return false
            }

            if (response.data.data.picture.length === 0) {
                this.setState({
                    showImages: <span className="p-1 ml-3">No picture attached!</span>
                })
                return false
            }

                //console.log("here", response.data.data.picture, response.data.data.picture.length)
                var showImages = []
                for (let i = 0; i < response.data.data.picture.length; i++) {
                    const srvImage= `${process.env.REACT_APP_API_URL}request-maintance-request-file/${this.props.data.id}/${response.data.data.picture[i]}`
                    showImages.push(<div className="col-md-4  mb-1 text-right "><a href={srvImage} target="_blank" rel="noopener noreferrer"  title={this.state.fileName}><img src={srvImage} className="img-fluid rounded" alt={this.state.fileName}/></a></div>)
                }

                this.setState({
                    showImages: showImages
                })
           
        })
    }

    componentDidMount = () => {

        if (this.props.data === undefined) return false;

        this.getImages();


        if (this.props.data.latitude !== null && this.props.data.longitude !== null) {
            this.setState({showMapCSSControl: "col-8  pr-0", showMap: <div className="col-4">
                <div className="bd bd-dashed d-flex align-items-center rounded p-2">
                    <div className="tx-center wd-0p">
                    <a href={`https://www.google.com/maps/place/${this.props.data.latitude},${this.props.data.longitude}`} target="_blank" rel="noopener noreferrer">
                    <img src={`https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=415x215&maptype=roadmap
                &markers=${this.props.data.latitude},${this.props.data.longitude}&key=${process.env.REACT_APP_API_GOOGLE_MAPS}`} 
                        className="w-100" alt="My Location"/>
                    </a>
                    </div>
                </div>
            </div>})
        }

       if (this.props.data.idStatusServiceRequest === 0) {
       this.setState({showActionButtons: <div>
                                            <button type="button" className="btn btn-success pd-x-25 mr-2" onClick={() => this.statusUpdate(1)}><i className="ion ion-flag lh-0 tx-white op-7 mr-2"></i>  Approve</button>
                                            <button type="button" className="btn btn-danger pd-x-25" onClick={() => this.statusUpdate(9)}><i className="ion ion-trash-a lh-0 tx-white op-7 mr-2"></i> Archive</button>        
                                            </div>})
       } else if (!this.props.data.idService) {

       this.setState({showActionButtons: <div>
                                            <button type="button" className="btn btn-light pd-x-25" onClick={() => this.statusUpdate(0)}><i className="ion ion-reply lh-0 op-7 mr-2"></i> Send Back to Inbox</button>        
                                            </div>})
        }
        
        if (this.props.data.howOfenValue !== null) {
        this.setState({recurrent: <span>Yes -> {this.props.data.howOfenValue}/{this.props.data.mscale}</span>})
        } else {
            this.setState({recurrent: "No"})
        }
    }


    statusUpdate = status => {
        axios({
            method: "put",
            url: `${process.env.REACT_APP_API_URL}maintenanceRequest/statusUpdate/${this.props.data.id}/${status}`,
            data: { "userId" : localStorage.getItem('userId') }

        })
        .then(response => {
            if (response.data.success === true) {
                this.props.reloadListRequest();
            }
        })
    }



    render() {
       // console.log(this.props.data, "pr")
        if (this.props.data === undefined) return false;

        const  {name, department, createdIn, details, assetId, assetName} = this.props.data;
        const date = new Date(createdIn);
        const fDate = date.toUTCString();

        function showAsset(id, assetName) {
            if (id !== null) {
                return <Link to={`/admin/assets/${id}/?viewonly=1`} target="_blank">{assetName}</Link>
            }
            return <span>Asset not provided!</span> 
        }

        return (
            <div className="br-mailbox-body">
                <div className="br-msg-header d-flex justify-content-between">
                    <div className="media align-items-center">
                    <img src={`http://via.placeholder.com/280x280/0965c1/ffffff/?text=${name.substring(0,1)}`} className="wd-60 rounded-circle" alt="" />
                    <div className="media-body mg-l-10">
                        <p className="tx-inverse tx-medium mg-b-0">{ name } ({ department })</p>
                        <p className="tx-12 mg-b-0">
                        <span>{ fDate }</span>
                        </p>
                    </div>
                    </div>
                    {this.state.showActionButtons}
                </div>


                <div className="br-msg-body col-">
                    <h6 className="tx-inverse mg-b-25 lh-4">Message sent via CMMS</h6>

                    <p>{ details }</p>

                   

                    <div>
                        <div className="bd bd-dashed d-flex align-items-center rounded p-2">
                            <div className="tx-center wd-0p">
                            <strong>Asset related with this request: </strong> {showAsset(assetId, assetName)}
                            </div>
                        </div>
                    </div>    

                    <div>
                        <div className="bd bd-dashed d-flex align-items-center rounded p-2 mt-2">
                            <div className="tx-center wd-0p">
                            <strong>Should it be recurrent maintenance? </strong> {this.state.recurrent}
                            </div>
                        </div>
                    </div>    
                    


                    
                    <div className="row mt-2">

                    
                    <div className={`${this.state.showMapCSSControl}`}>
                        <div className="bd bd-dashed d-flex align-items-center rounded p-2 ">
                            <div className="tx-center wd-0p">
                            <div className="row b-success">
                                {this.state.showImages}
                            </div>
                            </div>
                        </div>
                    </div>

                    {this.state.showMap}
                    </div>


                    
                    
                </div>

                
            </div>
        );
    }
}

export default InboxMainPanel;