import React, { Component } from "react";
import AlertMessage from "./general/AlertMessage";
import localStorage from 'localStorage';
require('dotenv').config()

const axios = require("axios");

class Login extends Component {
  constructor(props) {
    super(props);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      email: "",
      password: "",
      errorMessage: ""
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
      errorMessage: ""
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
      errorMessage: ""
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const credentials = {
      email: this.state.email,
      password: this.state.password
    };
    var self = this;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}authentication`,
      data: credentials
    })
      .then(function(response) {
        // handle success
        console.log(response)
        if (response.data.data.authorized === false) {
          return self.setState({
            errorMessage: (
              <AlertMessage
                message={response.data.data.message}
                messageType="danger"
                key="2"
              />
            )
          });
        }
        console.log("myuser", response.data.data)
        localStorage.setItem('userFirstId', response.data.data.userId);
        localStorage.setItem('userId', response.data.data.userId);
        localStorage.setItem('userFirstName', response.data.data.firstName);
        return window.location.replace("/admin/assets");
      })
      .catch(function(error) {
        // handle error
        self.setState({
          errorMessage: (
            <AlertMessage message={`Connection Failure! ${error}`} messageType="danger" key="1" />
          )
        });
        console.log(error);
      });
  }

  render() {
    return (
      <div className="d-flex align-items-center justify-content-center ht-100v login-background">
        <div className="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-white rounded shadow-base">
          <div className="signin-logo tx-center tx-28 tx-bold tx-inverse">
            <div>
              <img
                srcSet="./img/logo-superior-group.png"
                className="logo-sidebar"
                alt=""
              />
            </div>
          </div>
          <div className="tx-center mg-b-30">Assets Control</div>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your email"
                value={this.state.email}
                onChange={this.onChangeEmail}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Enter your password"
                value={this.state.password}
                onChange={this.onChangePassword}
              />
              <a href="/" className="tx-info tx-12 d-block mg-t-10">
                Forgot password?
              </a>
            </div>
            <input
              type="submit"
              className="btn btn-info btn-block mb-3"
              value="Sign In"
            />

            {this.state.errorMessage}
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
