import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import AlertMessageBootstrap from "../general/AlertMessageBootstrap";
import SelectOptionItem from "../general/SelectOptionItem";
import AssetModalParent from "../assets/AssetModalParent";
import AssetChildList from "../assets/AssetChildList";
import AssetPictures from "../assets/AssetPictures"
import {leftZero} from "../general/generalJsFunctions";
import ScheduleMaintenance from "../scheduleMaint/scheduleMaintenance";

require('dotenv').config()
const axios = require("axios");

class AssetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetFormatedId: "",
      RedirectPage: "",
      AlertMessage: "",
      //Table Fields:
      name: "",
      aka: "",
      glcode: "",
      description: "",
      idCompany: "",
      idLocation: "",
      idAssetArea: "",
      idAssetCategory: "",
      idAssetBrand: "",
      idPowerType: "",
      model: "",
      serial: "",
      yrManuf: "",
      dtAcquisition: "",
      vlrPurchased: "",
      idAssetStatus: "",
      idAssetCondition: "",
      idMScale: "",
      recurringMaint: "",
      rego: "",
      dtExpire: "",
      power: "",
      idFuelType: "",
      meterHrs: 0,
      meterKm: 0,
      meterTime: 0,
      comment: "",
      ///ssss
      idCompanyOption: false,
      idLocationOption: false,
      idAssetAreaOption: false,
      idAssetCategoryOption: false,
      idAssetBrandOption: false,
      idAssetStatusOption: false,
      idAssetConditionOption: false,
      idMScaleOption: false,
      idFuelTypeOption: false,
      idPowerTypeOption: false,
      idParent: null,
      parentFieldValeu: "",
      styleParentBt: {},
      styleParentFiled: {display: 'none'},
      componentParentModel: false,
      callAssetChildList: false,
      scheduleMaintenance: "",
      linkSchedule: ""
    };

    //Loading SelectOptions
    this.getSelectOptionData("company", "idCompanyOption");
    this.getSelectOptionData("location", "idLocationOption");
    this.getSelectOptionData("assetArea", "idAssetAreaOption");
    this.getSelectOptionData("assetCategory", "idAssetCategoryOption");
    this.getSelectOptionData("assetBrand", "idAssetBrandOption");
    this.getSelectOptionData("assetStatus", "idAssetStatusOption");
    this.getSelectOptionData("assetMScale", "idMScaleOption");
    this.getSelectOptionData("assetCondition", "idAssetConditionOption");
    this.getSelectOptionData("fuelType", "idFuelTypeOption");
    this.getSelectOptionData("powerType", "idPowerTypeOption");

    //Loading Data
    //this.handleData();
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.handleCloseParentModule = this.handleCloseParentModule.bind(this);
    this.handleParentField = this.handleParentField.bind(this);
  }

  openParentModule(e) {

    var showModule = []
    showModule.push(<AssetModalParent
                      unmountMe={this.handleCloseParentModule}
                      selectAsset={this.handleParentField}
                      title={"Select Asset Parent"}/>)
    this.setState({componentParentModel: showModule});
  }

  handleCloseScheduleMaintenance = () => {
    this.setState({scheduleMaintenance: "" });
  }

  handleScheduleMaintenance = () => {
    this.setState({scheduleMaintenance: <ScheduleMaintenance onClose={this.handleCloseScheduleMaintenance} id={this.props.match.params.id} name={this.state.name} />});
  }
  

  handleCloseParentModule() {
    this.setState({componentParentModel: false});
  }

  handleParentField = (id, name) => {
    this.setState({ componentParentModel: false });
      this.setState({styleParentBt: {display: 'none'},
        styleParentFiled: {},
        idParent: id,
        parentFieldValeu: name})
  }

  removeParentModule() {
    this.setState({idParent: null,
      parentFieldValeu: "",
      styleParentBt: {},
      styleParentFiled: {display: 'none'},})
  }

  getSelectOptionData(table, selectId) {
    var self = this;
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}sidetable/${table}?status=1&limit=1000`
    })
      .then(function(response) {
        var selectComponent = [];
        //console.log(response)
        var selectData = response.data.data.results;
        for (let i = 0; i < selectData.length; i++) {
          selectComponent.push(
            <SelectOptionItem
              id={selectData[i].id}
              name={selectData[i].name}
              key={`${selectId}${selectData[i].id}`}
            />
          );
        }
        self.setState({ [selectId]: selectComponent });
      })
      .catch(function(response) {
        console.log(response);
        var CAesslertMessage = [];
        CAesslertMessage.push(
          <AlertMessageBootstrap
            messageType="danger"
            message={`getSelectOptionData failure! (${response})`}
            unmountMe={self.handleCloseAlert}
          />
        );
        self.setState({ AlertMessage: CAesslertMessage });
      });
  }

  handleCloseAlert() {
    this.setState({ AlertMessage: false });
  }

  handleData() {
    if (!isNaN(this.props.match.params.id)) {

      this.setState({linkSchedule: <Link
        onClick={this.handleScheduleMaintenance}
        className="btn btn-outline-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2 mr-4"
      >
        Schedule Maintenance
      </Link>})
      var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}asset/${self.props.match.params.id}`
      })
        .then(function(response) {
          var datarow = response.data.data.results[0];
          self.setState({
            assetFormatedId: leftZero(6,self.props.match.params.id),
            name: datarow.name,
            aka:  datarow.aka,
            glcode: datarow.glcode,
            description: datarow.description,
            idCompany: datarow.idCompany,
            idLocation: datarow.idLocation,
            idAssetArea: datarow.idAssetArea,
            idAssetCategory: datarow.idAssetCategory,
            idAssetBrand: datarow.idAssetBrand,
            model: datarow.model,
            serial: datarow.serial,
            yrManuf: datarow.yrManuf,
            dtAcquisition: datarow.dtAcquisition,
            vlrPurchased: datarow.vlrPurchased,
            idAssetStatus: datarow.idAssetStatus,
            idAssetCondition: datarow.idAssetCondition,
            idMScale: datarow.idMScale,
            recurringMaint: datarow.recurringMaint,
            rego: datarow.rego,
            dtExpire: datarow.dtExpire,
            power: datarow.power,
            idPowerType: datarow.idPowerType,
            idFuelType: datarow.idFuelType,
            meterHrs: datarow.meterHrs,
            meterKm: datarow.meterKm,
            meterTime: datarow.meterTime,
            comment: datarow.comment,
            idParent: datarow.idParent,
            parentFieldValeu: datarow.nameParent
          });

          self.handleDataAssetChildList();

          if (self.state.idParent == null) {
            self.setState({styleParentBt: {},
                          styleParentFiled: {display: 'none'}})
          } else {
            self.setState({styleParentBt: {display: 'none'},
              styleParentFiled: {}
              })
          }

          if (self.state.dtAcquisition !== null ) {
            var fdtAcquisition= self.state.dtAcquisition.substring(0, 10)
            self.setState({dtAcquisition: fdtAcquisition})
          }

          if (self.state.dtExpire !== null ) {
            var fdtExpire = self.state.dtExpire.substring(0, 10)
            self.setState({dtExpire: fdtExpire})
          }

          //console.log("State");
          //console.log(self.state);
        })
        .catch(function(response) {
          console.log(response);
        });
    }
  }

  handleDataAssetChildList() {
    var self = this;
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}assetchildlist/${self.props.match.params.id}`
      })
        .then(function(response) {
          var datarow = response.data.data.results;
          if (datarow.length !== 0) {
              self.setState({callAssetChildList: <AssetChildList dataRow={datarow} key={self.props.match.params.id}/>})
          }
        })
    //
  }

  handleChange = e => {
    const value = e.target.value;
    const id = e.target.id;
    this.setState({ [id]: [value] });
    //console.log(this.state.meterHrs)
  };

  submitForm() {
    var msgErro = ""
    var f = this.state
    if (f.name === "") { msgErro += "Name, "}
    if (f.idCompany === "" || f.idCompany === 0 ) { msgErro += "Company, "}
    if (f.idLocation === "" || f.idLocation === 0 ) { msgErro += "Location, "}
    if (f.idAssetCategory === "" || f.idAssetCategory === 0 ) { msgErro += "Category, "}
    if (f.idAssetStatus === "" || f.idAssetStatus === 0 ) { msgErro += "Status, "}

    //Numeric
    if (!Number(f.yrManuf) || f.yrManuf === "") {this.setState({yrManuf: "0"})}
    if (!Number(f.recurringMaint)) {this.setState({recurringMaint: "0"})}
    if (!Number(f.vlrPurchased)) {this.setState({vlrPurchased: "0"})}
    if (!Number(f.meterHrs)) {this.setState({meterHrs: "0"})}
    if (!Number(f.meterHrs)) {this.setState({meterHrs: "0"})}
    if (!Number(f.meterTime)) {this.setState({meterTime: "0"})}
    // SELECTS
    if (!Number(f.idAssetArea) || f.idAssetArea==="") {this.setState({idAssetArea: "0"})}
    if (!Number(f.idAssetBrand)) {this.setState({idAssetArea: "0"})}
    if (!Number(f.idAssetCondition)) {this.setState({idAssetCondition: "0"})}
    if (!Number(f.idMScale)) {this.setState({idMScale: "0"})}
    if (!Number(f.idFuelType)) {this.setState({idFuelType: "0"})}
    if (!Number(f.idPowerType)) {this.setState({idPowerType: "0"})}



    if (msgErro === "") {
      if (!isNaN(this.props.match.params.id)) {
        this.updateData();
      } else {
        this.insertData();
      }
    } else {
      var CAesslertMessage = [];
      CAesslertMessage.push(
        <AlertMessageBootstrap
          messageType="danger"
          message={"Invalid field(s): " + msgErro + "."}
          unmountMe={this.handleCloseAlert}
          key="09"
        />
      );
      this.setState({ AlertMessage: CAesslertMessage });
    }
  }

  insertData() {
    var self = this;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}asset/`,
      data: {name: self.state.name,
        aka: self.state.aka,
        glcode: self.state.glcode,
        description: self.state.description,
        idCompany: self.state.idCompany,
        idLocation: self.state.idLocation,
        idAssetArea: self.state.idAssetArea,
        idAssetCategory: self.state.idAssetCategory,
        idAssetBrand: self.state.idAssetBrand,
        model: self.state.model,
        serial: self.state.serial,
        yrManuf: self.state.yrManuf,
        dtAcquisition: self.state.dtAcquisition,
        vlrPurchased: self.state.vlrPurchased,
        idAssetStatus: self.state.idAssetStatus,
        idAssetCondition: self.state.idAssetCondition,
        idMScale: self.state.idMScale,
        recurringMaint: self.state.recurringMaint,
        rego: self.state.rego,
        dtExpire: self.state.dtExpire,
        power: self.state.power,
        idPowerType: self.state.idPowerType,
        idFuelType: self.state.idFuelType,
        meterHrs: self.state.meterHrs,
        meterKm: self.state.meterKm,
        meterTime: self.state.meterTime,
        comment: self.state.comment,
        idParent: self.state.idParent}
    })
      .then(function(response) {
        if (Number(response.data.id)) {
          var RedirectPage = [];

          RedirectPage.push(<Redirect to={`${response.data.id}`} key="1" />);
          self.setState({ RedirectPage: RedirectPage });

          var CAlertMessage = [];
          CAlertMessage.push(
            <AlertMessageBootstrap
              messageType="success"
              message={response.data.msg}
              unmountMe={self.handleCloseAlert}
            />
          );
          self.setState({ AlertMessage: CAlertMessage });
        } else {
          //console.log(response);
          var CAesslertMessage = [];
          CAesslertMessage.push(
            <AlertMessageBootstrap
              messageType="danger"
              message="Insert failure! (1)"
              unmountMe={self.handleCloseAlert}
            />
          );
          self.setState({ AlertMessage: CAesslertMessage });
        }
      })
      .catch(function(response) {
        console.log(response);
        var CAesslertMessage = [];
        CAesslertMessage.push(
          <AlertMessageBootstrap
            messageType="danger"
            message="Insert failure! (0)"
            unmountMe={self.handleCloseAlert}
          />
        );
        self.setState({ AlertMessage: CAesslertMessage });
      });
  }

  updateData() {
    var self = this;
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}asset/${this.props.match.params.id}`,
      data: {
        name: self.state.name,
        aka: self.state.aka,
        glcode: self.state.glcode,
        description: self.state.description,
        idCompany: self.state.idCompany,
        idLocation: self.state.idLocation,
        idAssetArea: self.state.idAssetArea,
        idAssetCategory: self.state.idAssetCategory,
        idAssetBrand: self.state.idAssetBrand,
        model: self.state.model,
        serial: self.state.serial,
        yrManuf: self.state.yrManuf,
        dtAcquisition: self.state.dtAcquisition,
        vlrPurchased: self.state.vlrPurchased,
        idAssetStatus: self.state.idAssetStatus,
        idAssetCondition: self.state.idAssetCondition,
        idMScale: self.state.idMScale,
        recurringMaint: self.state.recurringMaint,
        rego: self.state.rego,
        dtExpire: self.state.dtExpire,
        power: self.state.power,
        idPowerType: self.state.idPowerType,
        idFuelType: self.state.idFuelType,
        meterHrs: self.state.meterHrs,
        meterKm: self.state.meterKm,
        meterTime: self.state.meterTime,
        comment: self.state.comment,
        idParent: self.state.idParent
      }
    })
      .then(function(response) {
        if (response.data.success === true) {
          var CAlertMessage = [];
          CAlertMessage.push(self.state.AlertMessage);
          CAlertMessage.push(
            <AlertMessageBootstrap
              messageType="success"
              message={response.data.msg}
              unmountMe={self.handleCloseAlert}
            />
          );
          self.setState({ AlertMessage: CAlertMessage });
        } else {
          //console.log(response);
          var CAesslertMessage = [];
          CAesslertMessage.push(
            <AlertMessageBootstrap
              messageType="danger"
              message="Update failure! (1)"
              unmountMe={self.handleCloseAlert}
            />
          );
          self.setState({ AlertMessage: CAesslertMessage });
        }
      })

      .catch(response => {
        console.log("Failure(0)");
        console.log(
          `url: ${process.env.REACT_APP_API_URL}assets/${this.props.match.params.id}`
        );
        console.log(self.state);
        console.log(response);
        var CAesslertMessage = [];
        self.setState({ AlertMessage: " " });
        CAesslertMessage.push(
          <AlertMessageBootstrap
            messageType="danger"
            message="Update failure! (0)"
            unmountMe={self.handleCloseAlert}
          />
        );
        self.setState({ AlertMessage: CAesslertMessage });
      });
  }

  componentDidMount() {
    this.handleData();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let viewOnly = params.get('viewonly');
    if (viewOnly === "1") {
      console.log("viewOnly" + search)
      this.setState({
        viewOnly: true
      })
    } else {
      console.log("test permission")
      var permission = 1
      if (permission === 0) {
        window.location.href = "/admin/assets";
      }
      this.setState({viewOnly: false })
    }
  }

  serialGenerator() {
    var mask = '';
    var chars = '#';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    var result = '';
    for (var i = 0; i <= 9; ++i) {

      result += mask[Math.floor(Math.random() * mask.length)];
      if (i === 6 || i === 13) result += "-"
    }
   this.setState({serial: result});
   console.log(result)
  }

  render() {
    return (
      <div className="br-mainpanel">
        
        {this.state.RedirectPage}
        <div className="br-pageheader pd-y-15 pd-l-20">
          <nav className="breadcrumb pd-0 mg-0 tx-12">
            <a className="breadcrumb-item" href="index.html">
              Superior Jetties
            </a>
            <a className="breadcrumb-item" href="/">
              Utilities
            </a>
            <span className="breadcrumb-item active">
              {this.props.details.title}
            </span>
          </nav>
        </div>

        <div className="d-flex align-items-center justify-content-between mg-b-30 pd-x-30 pd-t-30">
          <div>
            <h4 className="tx-gray-800 mg-b-5">{this.props.details.title}</h4>
          </div>

          <div>
          
          {this.state.scheduleMaintenance}
          {this.state.linkSchedule}


          <Link
            onClick={this.submitForm.bind(this)}
            className="btn btn-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2 mr-4"
          >
            Submit Form
          </Link>

          <Link
            to="/admin/assets"
            className="btn btn-outline-primary btn-oblong tx-11 tx-uppercase tx-mont tx-medium tx-spacing-1 pd-x-30 bd-2"
          >
            Go Back
          </Link>
          </div>

        </div>

        <div className="br-pagebody">

          <div className="br-section-wrapper">
            <div className="table-wrapper">
            {this.state.AlertMessage}
              <div className="form-layout">
                <div className="row mg-b-25">

                  {/* Asset # */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        Asset # <span className="tx-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={'form-control view-only'}
                        id="idAsset"
                        value={this.state.assetFormatedId}
                      />
                    </div>
                  </div>
                  {/* AKA */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        AKA
                      </label>
                      <input
                        type="text"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="aka"
                        placeholder={`AKA`}
                        value={this.state.aka}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* GL CODE */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        GL Code
                      </label>
                      <input
                        type="text"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="glcode"
                        placeholder={`GL Code`}
                        value={this.state.glcode}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>


                  {/* Asset Name */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        Asset Name <span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="name"
                        placeholder={`Asset Name`}
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {/* Company */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        Company <span className="tx-danger">*</span>
                      </label>
                      <select
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="idCompany"
                        value={this.state.idCompany}
                        onChange={this.handleChange}
                      >
                        <option></option>
                        {this.state.idCompanyOption}
                      </select>
                    </div>
                  </div>

                  {/* Location */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        Location <span className="tx-danger">*</span>
                      </label>
                      <select
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="idLocation"
                        value={this.state.idLocation}
                        onChange={this.handleChange}
                      >
                        <option></option>
                        {this.state.idLocationOption}
                      </select>
                    </div>
                  </div>

                  {/* Area */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Area</label>
                      <select
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="idAssetArea"
                        value={this.state.idAssetArea}
                        onChange={this.handleChange}
                      >
                        <option></option>
                        {this.state.idAssetAreaOption}
                      </select>
                    </div>
                  </div>

                  {/* Category */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        Category <span className="tx-danger">*</span>
                      </label>
                      <select
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="idAssetCategory"
                        value={this.state.idAssetCategory}
                        onChange={this.handleChange}
                      >
                        <option></option>
                        {this.state.idAssetCategoryOption}
                      </select>
                    </div>
                  </div>

                  {/* Brand */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Brand</label>
                      <select
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="idAssetBrand"
                        value={this.state.idAssetBrand}
                        onChange={this.handleChange}
                      >
                        <option></option>
                        {this.state.idAssetBrandOption}
                      </select>
                    </div>
                  </div>

                  {/* Asset Description */}
                  <div className="col-lg-8">
                    <div className="form-group">
                      <label className="form-control-label">Asset Description</label>
                      <textarea rows="3" className={this.state.viewOnly?'form-control mg-t-10 view-only':'form-control mg-t-10'}
                      placeholder="Description" id="description" onChange={this.handleChange} value={this.state.description} />
                    </div>
                  </div>

                  {/* Asset Parent */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label d-block">Asset Parent</label>

                      <Link onClick={() => this.openParentModule()} style={this.state.styleParentBt}  className="form-control-label mt-2 btn btn-warning" >
                        Click here to search a parent asset<br />(if applicable)
                        </Link>

                      <div style={this.state.styleParentFiled}>
                      <input
                        type="hidden"
                        className={'form-control view-only mt-2'}
                        id="assetParentName"
                        value={this.state.parentFieldValeu}
                        style={this.state.styleParentFiled}
                      /> <Link className="mb-3" to={`/admin/assets/${this.state.idParent}`} target="_blank" >{this.state.parentFieldValeu} <i class="icon ion-share"></i></Link>
                      </div>

                      <Link onClick={() => this.removeParentModule()} style={this.state.styleParentFiled}  className="form-control-label mt-1 btn btn-danger" >
                        Remove
                      </Link>
                      <input
                        type="hidden"
                        id="idParent"
                        value={this.state.idParent}
                      />
                      {this.state.componentParentModel}
                    </div>
                  </div>



                  {this.state.callAssetChildList}

                  <AssetPictures idAsset={this.props.match.params.id} type="picture" title="Asset Images Gallery"/>

                  <div className="col-lg-12 mb-4 mt-4">
                    <hr />
                  </div>

                  {/* Model */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Model</label>
                      <input
                        type="text"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="model"
                        placeholder={`Model`}
                        value={this.state.model}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* Serial */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Serial <Link onClick={() => this.serialGenerator()} className>(Serial Number Generator)</Link></label>
                      <input
                        type="text"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="serial"
                        placeholder={`Serial #`}
                        value={this.state.serial}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* yrManuf */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        Year of Manufacture
                      </label>
                      <input
                        type="number"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="yrManuf"
                        value={this.state.yrManuf}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* dtAcquisition */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        Date of Purchase
                      </label>
                      <input
                        type="date"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="dtAcquisition"
                        value={this.state.dtAcquisition}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* vlrPurchased */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">$ Purchased</label>
                      <input
                        type="number"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="vlrPurchased"
                        value={this.state.vlrPurchased}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* vlrCurrent */}
                  {/* <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">$ Current</label>
                      <input
                        type="number"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="vlrCurrent"
                        value={this.state.vlrCurrent}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div> */}

                  <div className="col-lg-12 mb-4 mt-4">
                    <hr />
                  </div>

                  {/* Status */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        Status <span className="tx-danger">*</span>
                      </label>
                      <select
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="idAssetStatus"
                        value={this.state.idAssetStatus}
                        onChange={this.handleChange}
                      >
                        <option></option>
                        {this.state.idAssetStatusOption}
                      </select>
                    </div>
                  </div>
                  {/* Condition */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Condition</label>
                      <select
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="idAssetCondition"
                        value={this.state.idAssetCondition}
                        onChange={this.handleChange}
                      >
                        <option></option>
                        {this.state.idAssetConditionOption}
                      </select>
                    </div>
                  </div>

                  {/* recuringMant */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label
                        className="form-control-label"
                        style={{ display: "block" }}
                      >
                        Maint. Period
                      </label>
                      <input
                        type="number"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="recurringMaint"
                        value={this.state.recurringMaint}
                        onChange={this.handleChange}
                        style={{ display: "inline", width: "40%" }}
                      />
                      <select
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="idMScale"
                        value={this.state.idMScale}
                        onChange={this.handleChange}
                        style={{ display: "inline", width: "40%" }}
                      >
                        <option></option>
                        {this.state.idMScaleOption}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-12 mb-4 mt-4">
                    <hr />
                  </div>

                   {/* rego */}
                   <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Rego #</label>
                      <input
                        type="text"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="rego"
                        value={this.state.rego}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* dtExpire */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Expiry Date</label>
                      <input
                        type="date"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="dtExpire"
                        value={this.state.dtExpire}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* space */}
                  <div className="col-lg-4">
                    {" "}
                  </div>

                  {/* power */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label"
                      style={{ display: "block" }}>Power</label>
                      <input
                        type="text"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="power"
                        value={this.state.power}
                        onChange={this.handleChange}
                        style={{ display: "inline", width: "40%" }}
                      />
                      <select
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="idPowerType"
                        value={this.state.idPowerType}
                        onChange={this.handleChange}
                        style={{ display: "inline", width: "40%" }}
                      >
                        <option></option>
                        {this.state.idPowerTypeOption}
                      </select>

                    </div>
                  </div>

                  {/* idFuelType */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Fuel Type</label>
                      <select
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="idFuelType"
                        value={this.state.idFuelType}
                        onChange={this.handleChange}
                      >
                        <option></option>
                        {this.state.idFuelTypeOption}
                      </select>
                    </div>
                  </div>

                  {/* space */}
                  <div className="col-lg-4">
                    {" "}
                  </div>

                  {/* meterHrs */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Meter (Hrs)</label>
                      <input
                        type="number"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="meterHrs"
                        value={this.state.meterHrs}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* meterKm */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Meter (Kms)</label>
                      <input
                        type="number"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="meterKm"
                        value={this.state.meterKm}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* meterTime */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">Meter (Months)</label>
                      <input
                        type="number"
                        className={this.state.viewOnly?'form-control view-only':'form-control'}
                        id="meterTime"
                        value={this.state.meterTime}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* space */}
                  <div className="col-lg-4">
                    {" "}
                  </div>

                  {/* comment */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-control-label">Comments</label>
                      <textarea rows="3" className={this.state.viewOnly?'form-control view-only':'form-control'}
                      placeholder="Comments" id="comment" onChange={this.handleChange} value={this.state.comment} />
                    </div>
                  </div>

                  <AssetPictures idAsset={this.props.match.params.id} type="files" title="Extra Files"/>
                </div>
                
                <div className={this.state.viewOnly?'form-layout-footer view-only':''}>
                  <input
                    type="submit"
                    className="btn btn-primary mb-4"
                    onClick={this.submitForm.bind(this)}
                  />
                  {this.state.AlertMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="br-footer">
          <div className="footer-left">
            <div className="mg-b-2">
              Copyright &copy; 2019. Superior Jetties Group. All Rights
              Reserved.
            </div>
            <div>Developed by Media Booth Australia</div>
          </div>
        </footer>
      </div>
    );
  }
}

export default AssetForm;
