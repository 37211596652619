import React, { Component } from 'react';

class GeoLocation extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            //latitude: "-27.9803376",
            //longitude: "153.3861509"
            latitude: false,
            longitude: false,
            displayMap: "d-none"
        }
        this.getLocation(); 
    }    

    showPosition = position => {
            this.setState({latitude: position.coords.latitude, longitude: position.coords.longitude})
            this.props.sendPosition(position.coords.latitude, position.coords.longitude);
            setTimeout(() => {
              if (this.state.latitude !== false) {
                this.setState({displayMap: "d-block"});
              }
            }, 100);
      }

    getLocation = () => {
      console.log("1")
        if (navigator.geolocation) {
          
            navigator.geolocation.getCurrentPosition(this.showPosition);
            console.log(this.showPosition)
        } else {
          console.log("Geolocation is not supported by this browser.");
        }
      }

    render() {
        return (
            <div className={`display: ${this.state.displayMap} mb-2`}>
                <span className="text-primary mt-2"><i className="icon ion-pinpoint"></i> Your Location</span><br/>
                <img src={`https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=515x120&maptype=roadmap
          &markers=${this.state.latitude},${this.state.longitude}&key=${process.env.REACT_APP_API_GOOGLE_MAPS}`} 
                  className="w-100" alt="My Location"/>
            </div>
        );
    }
}

export default GeoLocation;