import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Geolocation from './general/GeoLocation';
import AssetModalParent from './assets/AssetModalParent';
import AlertMessageBootstrap from "./general/AlertMessageBootstrap";

require('dotenv').config()

const axios = require("axios");

class MaintenanceForm extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            showForm: "d-block",
            showThankYou: "",
            latitude: false,
            alertMessage: false,
            longitude: false,
            howOfenSelectItens: false,
            selectAsset: false,
            idAsset: false,
            assetFieldValeu: "",
            showRemove: false,
            howOften: "d-none",
            //database
            name:"",
            department:"",
            details:"",
            howOfenValue: "",
            howOfenSelect: "",
            checkRecurrent: 0, 
            //images
            selectedFiles: []
        }
        this.handleCloseParentModule = this.handleCloseParentModule.bind(this);
        this.handleParentField = this.handleParentField.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);

       
    }    


    getSelectOptionData(table) {
        var self = this;
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}sidetable/${table}?status=1&limit=1000`
        })
          .then(function(response) {
            var selectComponent = [];
            //console.log(response)
            var selectData = response.data.data.results;
            for (let i = 0; i < selectData.length; i++) {
              selectComponent.push(
                <option value={selectData[i].id} >{selectData[i].name}</option>
              );
            }
            self.setState({ howOfenSelectItens: selectComponent });
          })
          .catch(function(response) {
            console.log(response);
            var CAesslertMessage = [];
            CAesslertMessage.push(
              <AlertMessageBootstrap
                messageType="danger"
                message={`getSelectOptionData failure! (${response})`}
                unmountMe={self.handleCloseAlert}
              />
            );
            self.setState({ AlertMessage: CAesslertMessage });
          });
      }


    toggleMaintenace = () => {
        if (this.state.howOften === "d-none") {
            this.setState({howOften: "", checkRecurrent: 1}) 
        } else {
            this.setState({howOften: "d-none", checkRecurrent: 0}) 
        }
    }

    handleCloseAlert() {
        this.setState({
            alertMessage: false})
    }

    submitForm = () => {
    const {name, department, details, checkRecurrent, howOfenValue, howOfenSelect} = this.state;
    if (name === "" || department === "" || details === "") {
        this.setState({
            alertMessage: <AlertMessageBootstrap messageType="danger"
            message={`Please fill the required fields!`}
            unmountMe={this.handleCloseAlert}
            />
        })
        return false;
    }

    if (checkRecurrent === 1 && (howOfenValue==="" || howOfenSelect==="")) {
        this.setState({
            alertMessage: <AlertMessageBootstrap messageType="danger"
            message={`Please fill the details about recurrence!`}
            unmountMe={this.handleCloseAlert}
            />
        })
        return false;
    }

      const dataImages = new FormData()       
      if (this.state.selectedFiles !== null) {
        for(var x = 0; x<this.state.selectedFiles.length; x++) {
            dataImages.append('file', this.state.selectedFiles[x])
        }
      }

      const dataFields = {
        "name": this.state.name,
        "department" : this.state.department,
        "details": this.state.details,
        "idAsset": this.state.idAsset !== false ? this.state.idAsset : null,
        "latitude": this.state.latitude !== false ? this.state.latitude : null,
        "longitude": this.state.longitude !== false ? this.state.longitude : null,
        "attached": this.state.selectedFiles.length,
        "howOfenValue": this.state.howOfenValue !== "" ? this.state.howOfenValue : null,
        "howOfenPeriod": this.state.howOfenSelect !== "" ? this.state.howOfenSelect : null,
      }
      
      axios.post(`${process.env.REACT_APP_API_URL}maintenanceRequest/`, dataFields, {

      })
      .then(response => {
          if (this.state.selectedFiles.length !== 0) {
            axios.post(`${process.env.REACT_APP_API_URL}maintenanceRequest/${response.data.id}/upload`, dataImages, { // receive two parameter endpoint url ,form data 
            })
            .then(() => {
                this.setState({
                    showForm: "d-none",
                    showThankYou: <div className="alert alert-success p-5" role="alert">
                    Thank you! Your request has been sent.
                  </div>
                })
            })
            .catch(response => {
                console.log(response)
                this.setState({
                    alertMessage: <AlertMessageBootstrap messageType="danger"
                    message={`Failed!`}
                    unmountMe={this.handleCloseAlert}
                    />
                })
            })
          } else {

            this.setState({
                showForm: "d-none",
                showThankYou: <div className="alert alert-success p-5" role="alert">
                Thank you! Your request has been sent.
              </div>
            })
            
          }
          
        })
      .catch(response => { 
        this.setState({
        alertMessage: <AlertMessageBootstrap messageType="danger"
        message={`01 Failed!`}
        unmountMe={this.handleCloseAlert}
        />
    })})

    
      
    }

    handleSubmitImages = event => {
        var listOfSelectedFiles = [];
        for (let i = 0; i < event.target.files.length; i++) {
          listOfSelectedFiles.push(event.target.files[i])
          
        }
        this.setState({selectedFiles: listOfSelectedFiles})
        console.log("images", listOfSelectedFiles, this.state.selectedFiles)
        
      }

    sendPosition = (latitude, longitude) => {
        this.setState({latitude: latitude, longitude: longitude})
    }

    selectAsset = () => {
        this.setState({selectAsset: (<AssetModalParent 
            unmountMe={this.handleCloseParentModule}
            selectAsset={this.handleParentField}
            title="Select the Asset"
        />)})
    }
    
    handleCloseParentModule = () => {
        this.setState({selectAsset:false})
    }

    handleParentField = (id, name) => {
        this.setState({selectAsset: false });
        this.setState({
            idAsset: id,
            assetFieldValeu: name,
            showRemove: <Link onClick={this.removeAsset}>Remove</Link>})
      }
    
    removeAsset = () => {
        console.log("s")
        this.setState({
            idAsset: false,
            assetFieldValeu: "",
            showRemove: false
        })
    }

    handleChange = e => {
        const value = e.target.value;
        const id = e.target.id;
        this.setState({ [id]: value });
        //console.log(this.state.meterHrs)
    };

    componentDidMount() {
        this.getSelectOptionData("assetMScale")
    }

    render() {
        return (
        <div className="d-flex align-items-center justify-content-center ht-100v maintenance-background">
            
            <div className="login-wrapper wd-300 wd-xs-600 pd-25 pd-xs-40 bg-white rounded shadow-base" id="maintenance-block">
            <div className="signin-logo tx-center tx-28 tx-bold tx-inverse">
                <div>
                <a href="/maintenance">    
                <img
                    srcSet="./img/logo-superior-group.png"
                    className="logo-sidebar"
                    alt=""
                />
                </a>
                </div>
            </div>
            <div className="tx-center mg-b-30 mt-4">Request a Maintenance Service</div>

            {this.state.alertMessage}
            {this.state.showThankYou}
            <form className={this.state.showForm}>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Name*"
                        maxLength="255"
                        id="name"
                        value={this.state.name}
                        onChange={(e) => this.handleChange(e)}
                        required
                    />
                </div>

                <div className="form-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Department*"
                    maxLength="255"
                    id="department"
                    value={this.state.department}
                    onChange={(e) => this.handleChange(e)}
                    required
                />
                </div>
                <div className="row mb-3 text-right">
                    <div className="col-3 pr-0">
                        <Link onClick={() => this.selectAsset()}
                        className="btn btn-info form-control">
                            Search...
                        </Link>
                        {this.state.selectAsset}
                    </div>
                    <div className="col-9">
                    <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={this.state.assetFieldValeu}
                /><span className="text-right">{this.state.showRemove}</span>
                    </div>
                    
                </div>


                <div className="form-group">
                <textarea
                    className="form-control"
                    placeholder="Details*"
                    rows="5"
                    id="details"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.details}
                />
                </div>
                <div className="from-group mb-3">
                    <input type="checkbox" name="recurrent" value="1" onClick={this.toggleMaintenace}  /> Should it be recurrent maintenance?

                    <div className={`border border-warning bg-light p-2 mt-2 mb-2 ${this.state.howOften}`}>
                        <p>How often should it be done?</p>
                        <p>
                            <input
                                type="number"
                                className="form-control w-25 d-inline"
                                maxLength="10"
                                id="howOfenValue"
                                onChange={(e) => this.handleChange(e)}
                                value={this.state.howOfenValue}
                            /> {" / "}
                                <select
                                className="form-control w-25 d-inline"
                                id="howOfenSelect"
                                value={this.state.howOfenSelect}
                                onChange={this.handleChange}
                                >
                                    <option></option>
                                   {this.state.howOfenSelectItens}
                                </select>
                        </p>

                    </div>
                </div>

                <div className="form-group">
                    <input type="file" name="img" valeu="Choose a picture" onChange={this.handleSubmitImages} multiple></input>
                </div>
                
                <Geolocation sendPosition={this.sendPosition}/>
                <Link onClick={this.submitForm}
                className="btn btn-info btn-block mb-3">
                    Submit Request
                </Link>
            </form>
            {this.state.alertMessage}
            </div>
            
        </div>
        );
    }
}


export default MaintenanceForm;

