import React, { Component } from 'react';
import format from 'date-format';

require('dotenv').config()
const axios = require("axios");

class MaintTimeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idStatusMaintenanceOption: false,
            showData: "", 
        } 
    }

    handleData = () => {
        var self = this;
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}maint/timeline/${this.props.id}`
        })
        .then(function(response) {
          console.log(response.data.data.results)
          var dataResult = [];

          for (let i = 0; i < response.data.data.results.length; i++) {
            var { description, attachedFile, createdIn, status, firstName, secondName, files
            } = response.data.data.results[i];

            //let current_datetime = new Date(createdIn)
            //let formatted_date = current_datetime.getDate() + "/" + (current_datetime.getMonth() + 1) + "/" + current_datetime.getFullYear()
            let formatted_date = format.asString('dd/MM/yyyy hh:mm', new Date(createdIn))

            var icon = "ion-chatbox-working";

           

         
            var showattachedfile = "";
            if (attachedFile === 1) {

                if (files) { 
                    var listOfFiles = files.split(",");
                    var formatedListOfFiles = ""
                    for (let k = 0; k < listOfFiles.length; k++) {
                        formatedListOfFiles += `<span class="badge badge-info mr-1 mb-1 p-2"><a href="${process.env.REACT_APP_MAINT_FILE_PATH}${self.props.id}/${listOfFiles[k]}" target="_bank" class="text-white">${listOfFiles[k]}</a></span>`;
                    }

                    icon = "ion-paperclip ml-2";
                    showattachedfile = `<span className="mt-3"><br /><strong>Attached file(s):</strong>
                                        <br />${formatedListOfFiles}</span>`

                }

               
            }


            dataResult.push(<tr>
                                <td class="pd-l-20">
                                    <i class={`ion ${icon} tx-30 lh-0 tx-primary op-5`}></i>
                                </td>
                                <td>
                                    <span class="d-block mb-3" dangerouslySetInnerHTML={{__html: description}}></span>
                                    <span class="tx-11 d-block"><strong>{firstName} {secondName}</strong></span>
                                    <span class="tx-11 d-block"><i class="icon ion-calendar"></i> {formatted_date}</span>
                                    <span class="d-block"><i class={`ion ion-more tx-15 op-5`}></i> Status: {status}</span>
                                    <span class="d-block mb-3" dangerouslySetInnerHTML={{__html: showattachedfile}}></span>
                                </td>
            </tr>);         
          }

          self.setState({
            showData: dataResult
          })

     

        })
        .catch(function(response) {
          console.log(response);
        });
    }

    componentDidMount() {
        this.handleData();
    }


    /* 
    
    <tr>
                    <td class="pd-l-20">
                        <i class="ion ion-chatbox-working tx-30 lh-0 tx-primary op-5"></i>
                    </td>
                    <td>
                        <a href="" class="tx-inverse tx-14 tx-medium d-block">Mark K.</a>
                        <span class="tx-11 d-block">TRANSID: 1234567890</span>
                    </td>
                </tr>

    */
    render() {
        return (
            <tbody>
                {this.state.showData}
            </tbody>
        );
    }
}

export default MaintTimeline;