import React, { Component } from 'react';
import InboxCard from './components/InboxCard';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'

require('dotenv').config()
const axios = require("axios");

class MaintenanceListRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inboxCard: false,
            totalInbox: "",
            showLoadMore: false,
        }
    }

    callDetails = id => this.props.callDetails(id)

    componentDidMount() {
        this.handleData(0,0);
    }

       
    handleTotalInbox = () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}maintenanceRequest/inbox/list/0/0`
        })
          .then(response => {
            if (response.data.success === true) {
                var totalInbox = response.data.additional_data.pagination.num_records;
                this.setState({totalInbox: totalInbox})    
            } else {
                console.log("Conection failed!")
            }
          })          
    }

    handleData = (status, start) => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}maintenanceRequest/inbox/list/${status}/${start}`
        })
          .then(response => {
            if (status === 0) {
                var totalInbox = response.data.additional_data.pagination.num_records;
                this.setState({totalInbox: totalInbox})
            } else {
                this.handleTotalInbox();
            }

            var arrayInboxCard = []
            if (this.state.inboxCard !== "" && Number(start) !== 0) {
                arrayInboxCard.push(this.state.inboxCard)
            }

            if (response.data.success === true) {
                if (start === 0) {this.callDetails(response.data.additional_data.firstItem)}
                for (let i = 0; i < response.data.data.results.length; i++) {
                    arrayInboxCard.push(<InboxCard 
                        key={response.data.data.results[i].id}
                        data={response.data.data.results[i]}
                        callDetails={this.callDetails}
                    />)
                }
                this.setState({inboxCard: arrayInboxCard})

                const { more_items_in_collection, next_start } = response.data.additional_data.pagination
                if (more_items_in_collection === true) {
                    this.setState({showLoadMore: <button type="button" className="btn btn-secondary w-100" onClick={() => this.handleData(status, next_start)}>Load More...</button>})
                } else {
                    this.setState({showLoadMore: false})
                }
            } else {
                console.log("Conection failed!")
            }

          })          
    }

    render() {
        return (
            <div className="br-mailbox-list ps ps--theme_default ps--active-y" data-ps-id="f8c0dbb1-17c9-30e3-175b-c43b908f60ed">
                <div className="br-mailbox-list-header">
                    
                    <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-info pd-x-25 mr-2"  onClick={() => this.handleData(0, 0)}>Inbox ({this.state.totalInbox})</button>
                    <button type="button" className="btn btn-success pd-x-25 mr-2" onClick={() => this.handleData(1, 0)}>Approved</button>
                    <button type="button" className="btn btn-danger pd-x-25" onClick={() => this.handleData(9, 0)}>Archived</button>
                    </div>
                </div>
                <PerfectScrollbar>
                <div className="br-mailbox-list-body">
                    {this.state.inboxCard}
                    
                </div>
                <div className="m-2">
                    {this.state.showLoadMore}
                    
                </div>
                <div style={{marginBottom: 100}}>&nbsp;</div>
                </PerfectScrollbar>
        </div> 
        );
    }
}

export default MaintenanceListRequest;