import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalDialogDelete from '../general/ModalDialogDelete';
import ModalDialog from '../general/ModalDialog';
require('dotenv').config()
const axios = require("axios");

class SideTableRows extends Component {
  constructor(props) {
    super(props);
    this.state = {ModalDialog: ""}
    this.handleChildUnmount = this.handleChildUnmount.bind(this);
    this.handleChildDelete = this.handleChildDelete.bind(this);
    //this.refreshDataPanel = this.refreshDataPanel.bind(this);
  }

  handleClose = () => {
    this.props.unmountMe();
  }


  handleChildUnmount(){
    this.setState({ModalDialog: false});
  }

  handleChildDelete(id){
    var self = this
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}sidetable/${
        self.props.table
      }/${id}`
    })
      .then(function(response) {
        self.setState({ModalDialog: <ModalDialog message={response.data.msg} idRow={id} unmountMe={self.handleChildUnmount} />})
        self.handleClose();
      })
      .catch(function(response) {
        console.log(response);
      });

    this.setState({ModalDialog: false});
  }



  deleteRow = (idRow, name) => {
    this.setState({ModalDialog: ""})
    this.setState({ModalDialog: <ModalDialogDelete name={name} idRow={idRow} unmountMe={this.handleChildUnmount} deleteMe={this.handleChildDelete} />})
  }
  render() {
    var displayStatus = "";
    if (this.props.data.status === 1) {
      displayStatus = "Active";
    } else {
      displayStatus = "Disabled";
    }
    return (
        <tr>
          <td>{this.props.data.id}</td>
          <td>{this.props.data.name}</td>
          <td>{displayStatus}
          <div className="toggle-wrapper">
          <div className="toggle toggle-modern primary"></div>
        </div>
          </td>
          <td className={"text-right"}>
            <Link  to={this.props.parentpath + "/" + this.props.data.id}><i className="icon ion-edit tx-20" /></Link>{" "}
            <i className="icon ion-close tx-20 mg-l-20" onClick={() => this.deleteRow(this.props.data.id, this.props.data.name)} />
            {this.state.ModalDialog}
          </td>
        </tr>
    );
  }
}

export default SideTableRows;
