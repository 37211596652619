import React, { Component } from 'react';
import { Link } from "react-router-dom";
import  AssetChildListRow from './AssetChildListRow';

class AssetChildList extends Component {
    constructor(props) {
        super(props);
        this.state = { 
                        show: "d-none", //"d-none"
                        showAll: false,
                        showHide: "d-none", //"d-none"
                        showChildListRow: false,
                        showChildMessage: "This asset has ..."
                    }            
    }

  componentDidMount() {
    if (this.props.dataRow.length === 1) {
      this.setState({showChildMessage: `This asset has 1 child assets`})
    } else {
      this.setState({showChildMessage: `This asset has ${this.props.dataRow.length} children assets`})
    }
  }
  

    
    handleShow = () => {
        if(this.state.show==="d-none") {
            this.setState({show: false, showAll: "d-none", showHide: false})

            var varAssetChildListRow=[];
            for (let i = 0; i < this.props.dataRow.length; i++) {
                varAssetChildListRow.push(<AssetChildListRow dataRow={this.props.dataRow[i]} />)    
            }
            this.setState({showChildListRow: varAssetChildListRow}) 

        } else {
            this.setState({show: "d-none", showAll: false, showHide: "d-none"})
        }
    }

    render() {
        return (
            <div className="col-lg-12 mt-4">
            <div className="card shadow-base bd-0">
              <div className="card-header bg-info pd-20">
                <h6 className="card-title tx-uppercase tx-12 mg-b-0 text-white">{this.state.showChildMessage}</h6>
              </div>
              <table className={`table table-responsive mg-b-0 tx-12 ${this.state.show}`}>
                <thead>
                  <tr className="tx-10">
                    <th className="pd-y-5">Asset</th>
                    <th className="pd-y-5">Category</th>
                    <th className="pd-y-5">Status</th>
                  </tr>
                </thead>
                <tbody>
                  
                    {this.state.showChildListRow}


                </tbody>
              </table>
              <div className="card-footer tx-12 pd-y-15 bg-transparent">
                <Link onClick={() => this.handleShow()}>
                    <span className={this.state.showAll}><i className="fa fa-angle-down mg-r-5"></i>Show All Assets Child</span>
                    <span className={this.state.showHide}><i className="fa fa-angle-up mg-r-5"></i>Hide All Assets Child</span>
                </Link>
              </div>
            </div>
          </div>
        );
    }
}

export default AssetChildList;