import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AssetModalParentRows extends Component {
   
    selectItem(id, name) {
        this.props.selectItem(id, name);
    }

    render() {
        
        return (


                <tr>
                <th scope="row">{this.props.data.id}</th>
                <td>{this.props.data.aka}</td>
                <td>{this.props.data.name}</td>
                <td>
                <Link onClick={() => this.selectItem(this.props.data.id, this.props.data.name)} className="form-control-label mt-2 btn btn-primary" >
                    Select
                    </Link>
                </td>
                </tr>


        );
    }
}

export default AssetModalParentRows;