import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

// App Components
import Login from "./components/Login";
import MaintenanceForm from "./components/MaintenanceForm";
import MainControl from "./MainControl";

//import 'bootstrap/dist/css/bootstrap.min.css';

//google server - https://noble-aquifer-219505.appspot.com/api/

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route
              exact
              path="/login"
              component={() => <Login performUsers={this.performUsers} returnUser={this.performUsers}/>}
            />
            <Route
              exact
              path="/maintenance"
              component={() => <MaintenanceForm />}
            />

             <Route
              exact
              path="/admin/assets"
              component={() => (
                <MainControl />
              )}
            />
            <Route
              exact
              path="/admin/:id/:id"
              component={() => (
                <MainControl  />
              )}
            />
            <Route
              exact
              path="/admin/st/:id/:cod"
              component={() => (
                <MainControl  />
              )}
            />

            <Route
              exact
              path="/admin/maintenance/inbox"
              component={() => (
                <MainControl  />
              )}
            />

            <Route
              exact
              path="/admin/maintenance/"
              component={() => (
                <MainControl  />
              )}
            />

            <Route
              exact
              path="/admin/maintenance/form/:id"
              component={() => (
                <MainControl  />
              )}
            />


          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
